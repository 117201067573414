import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { CompanyContext } from 'pages/CompanyRequired';
import { TokenContext } from 'pages/TokenRequired';
import { FinchPayrollProvider, ImportType } from 'lib/interfaces';
import LoadingWidget from 'components/util/LoadingWidget';
import { makeStyles } from '@material-ui/core';
import {
  ConnectAccounting,
  ConnectPayrollCard,
  PayrollConnectedCard,
} from 'pages/dashboard/integrations';
import { Alert, Card, Flex, Text } from 'component-library';
import { PlaidIntegrationCard } from './components/plaidIntegration';
import { GovernmentProgramBundle, ProgramNameEnum } from 'lib/constants';
import { datadogLogs } from '@datadog/browser-logs';
import {
  PayrollGap,
  PayrollGapReason,
} from 'pages/dashboard/integrations/components/payrollIntegration/PayrollGap';
import { logContext } from 'logging';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import { FiscalTaxYearHasPayrollGap } from 'lib/helpers';
import { GetPayrollGapYearToCheck } from 'lib/payrollGap';
import { PayrollGapPostAnswerAlertMessage } from 'pages/dashboard/integrations/components/payrollIntegration/PayrollGapPostAnswerAlertMessage';
import {
  FeatureFlag,
  FeatureFlagProviderContext,
  FlagOption,
} from '@mainstreet/feature-flags-react';
import {
  FeatureFlagNameEnum,
  FeatureFlagValueEnum,
} from 'lib/constants/featureFlagConstants';
import { MSPlaidClientProvider } from 'lib/financial';
import PaymentMethod from 'components/PaymentMethod';
import { ChargeBeePaymentForm } from '../../../components/payments/ChargeBeePaymentForm';
import { useFeatureFlags } from 'stores/useStores';
import TalkToAnExpertButton from 'components/util/TalkToAnExpertButton';
import TaxStatus from 'products/tax-credits/features/unified-tax-credits/components/taxStatus/TaxStatus';

const useStyles = makeStyles(() => ({
  root: {
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    maxWidth: '1024px',
    boxSizing: 'border-box',
  },
  tokenRoot: {
    padding: '40px 80px',
  },
  header: {
    margin: '0 0 40px',
  },
  payrollCards: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
  },
  title: {
    marginTop: '40px',
    fontSize: '24px',
  },
}));

interface PayrollSystem {
  serviceName: FinchPayrollProvider | 'Manual';
  expired: boolean;
}

export const IntegrationsPage = () => {
  const classes = useStyles();
  const { company, setCompany } = useContext(CompanyContext);
  const { client } = useContext(Auth0FeatureContext);
  const { token, email } = useContext(TokenContext);
  const featureFlags = useFeatureFlags();
  const [payrollSystems, setPayrollSystems] = useState<PayrollSystem[]>([]);
  const [loading, setLoading] = useState(true);
  const [payrollGapConnectionAdded, setPayrollGapConnectionAdded] =
    useState(false);
  const [payrollConnectInProgress, setPayrollConnectInProgress] =
    useState<boolean>(false);
  const { getFlag } = useContext(FeatureFlagProviderContext);

  const isPayrollGapFlagEnabled = !!(
    getFlag?.(FeatureFlagNameEnum.GOV_CONNECT_PAYROLL_GAP) ===
    FeatureFlagValueEnum.ON
  );

  const showChargeBee = !!getFlag?.(
    FeatureFlagNameEnum.SHOW_CHARGEBEE_PAYMENT_METHOD,
  );

  const taxYearToCheckForPayrollGap = GetPayrollGapYearToCheck(
    company.programs,
  );

  const fedRdProgramAffectedByPayrollGap = company.programs.find(
    (program) =>
      program.name === ProgramNameEnum.FED_RD_TAX &&
      program.taxYear === taxYearToCheckForPayrollGap,
  );
  const [hasGapInPayroll, setHasGapInPayroll] = useState(true);
  const [
    showPostConnectionPayrollGapAlert,
    setShowPostConnectionPayrollGapAlert,
  ] = useState(false);
  const loggedIn = !!company; // this is false if the page is accessed via the "admin link"
  const hasLinkedPayrollSystem: boolean = payrollSystems.length > 0;
  const isPayrollConnectionRequired =
    company?.programs &&
    company.programs.some((program) =>
      GovernmentProgramBundle.includes(program.name),
    );
  const [payrollGapReason, setPayrollGapReason] = useState<
    PayrollGapReason | undefined
  >(undefined);

  useEffect(() => {
    if (isPayrollGapFlagEnabled && taxYearToCheckForPayrollGap) {
      client
        .PayrollImportStatus(company.id, taxYearToCheckForPayrollGap)
        .then((res) => {
          if (res) {
            setHasGapInPayroll(
              FiscalTaxYearHasPayrollGap(
                res?.firstPayStatementOfTaxYear,
                res?.lastPayStatementOfTaxYear,
                company.fiscalYearEndDate,
              ),
            );
          }
        });
    }
  }, [
    client,
    company.fiscalYearEndDate,
    company.id,
    isPayrollGapFlagEnabled,
    taxYearToCheckForPayrollGap,
  ]);

  const onPayrollGapReasonAnswered: (reason: PayrollGapReason) => void = (
    reason,
  ) => {
    setPayrollGapReason(reason);
    if (reason !== PayrollGapReason.CHANGED_PROVIDERS) {
      setShowPostConnectionPayrollGapAlert(true);
    } else {
      setShowPostConnectionPayrollGapAlert(false);
    }
  };

  const shouldShowPayrollGapCta =
    showPostConnectionPayrollGapAlert || payrollGapConnectionAdded;

  const showConnectNewPayroll =
    !hasLinkedPayrollSystem ||
    payrollConnectInProgress ||
    (isPayrollGapFlagEnabled &&
      payrollGapReason === PayrollGapReason.CHANGED_PROVIDERS &&
      !shouldShowPayrollGapCta);

  const loadPayrollProviders = (token?: string, email?: string) => {
    setLoading(true);
    client.FinchLinkedServices(token, email).then((providers) => {
      if (providers.errorMsg) {
        datadogLogs.logger.error(
          'IntegrationsPage.loadPayrollProviders',
          logContext({
            error: providers.errorMsg,
            company,
          }),
        );
      } else if (providers?.data !== undefined) {
        setPayrollSystems([
          ...providers.data.services.map((service) => {
            return {
              serviceName: service,
              expired: false,
            };
          }),
          ...providers.data.expiredServices.map((service) => {
            return {
              serviceName: service,
              expired: true,
            };
          }),
        ]);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    loadPayrollProviders(token, email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, token]);

  const refreshCompany = () => {
    return client.CurrentLoggedInCompany().then((company) => {
      if (company) {
        setCompany(company);
      }
    });
  };

  const onPayrollConnected = async () => {
    setLoading(true);
    setPayrollConnectInProgress(false);
    loadPayrollProviders(token, email);

    if (hasGapInPayroll) {
      setPayrollGapConnectionAdded(true);
    }
    // For logged-in users, we need to refresh the company context so that the "payrollConnected" bit is set in the dashboard
    if (loggedIn) {
      await refreshCompany();
    }
    setLoading(false);
  };

  const connectManualPayroll = () => {
    if (!loggedIn) {
      return undefined; // currently only logged-in users can upload manual payroll, because that is easier for now
    }
    setPayrollConnectInProgress(false);
    setPayrollSystems([
      ...payrollSystems,
      {
        serviceName: 'Manual',
        expired: false,
      },
    ]);
  };

  return (
    <div className={classes.root} data-testid='integrations-page'>
      {loading ? (
        <LoadingWidget />
      ) : (
        <>
          <Flex justifyContent='space-between' alignItems='center' gap={24}>
            <Text size={40} variant='medium'>
              Connections
            </Text>
            {featureFlags.showTalkToAnExpertButton && <TalkToAnExpertButton />}
          </Flex>
          <Text size={23} variant='medium'>
            Payroll
          </Text>
          <Card noMargin noBorder noBoxShadow>
            <Alert
              type='info'
              text={
                'It can take up to 20 minutes for changes on your payroll connection to be reflected in our system.'
              }
            />
          </Card>
          {!hasLinkedPayrollSystem && isPayrollConnectionRequired && (
            <Alert
              text={'Payroll connection is required for tax processing'}
              type='warning'
              variant='primary'
            />
          )}
          <div className={classes.payrollCards}>
            {payrollSystems.map((system, index) => (
              <PayrollConnectedCard
                key={index}
                payrollSystem={system.serviceName}
                onPayrollConnected={onPayrollConnected}
                expiredPayrollSystem={system.expired}
                connectAnother={
                  !showConnectNewPayroll && index === payrollSystems.length - 1
                }
                setPayrollConnectInProgress={setPayrollConnectInProgress}
                importType={ImportType.ConnectionsPage}
              />
            ))}
            {hasGapInPayroll &&
              taxYearToCheckForPayrollGap &&
              isPayrollGapFlagEnabled && (
                <PayrollGap
                  taxYear={taxYearToCheckForPayrollGap}
                  payrollGapWasAnsweredWithReason={onPayrollGapReasonAnswered}
                />
              )}
            {showConnectNewPayroll && (
              <ConnectPayrollCard
                onPayrollConnected={onPayrollConnected}
                connectManualPayroll={
                  loggedIn ? connectManualPayroll : undefined
                }
                required={false}
                importType={ImportType.ConnectionsPage}
              />
            )}
            {shouldShowPayrollGapCta &&
            payrollGapReason &&
            fedRdProgramAffectedByPayrollGap ? (
              <PayrollGapPostAnswerAlertMessage
                payrollGapReason={payrollGapReason}
                programId={fedRdProgramAffectedByPayrollGap.id}
              />
            ) : (
              <></>
            )}
          </div>
          <div className={classes.title}>Tax Filings</div>

          <TaxStatus />
          {loggedIn && (
            <>
              <div className={classes.title}>Accounting software</div>
              <ConnectAccounting
                onAccountingConnected={refreshCompany}
                returnPage={'connections'}
                isRequired={false}
              />
            </>
          )}
          {showChargeBee ? (
            <ChargeBeePaymentForm />
          ) : (
            <FeatureFlag flagName={FeatureFlagNameEnum.PAYMENTS_NEW_UI_V2}>
              <FlagOption flagValue={FeatureFlagValueEnum.OFF}>
                <div className={classes.title}>Banking information</div>
                <PlaidIntegrationCard />
              </FlagOption>
              <FlagOption flagValue={FeatureFlagValueEnum.ON}>
                <>
                  <div className={classes.title}>Payment information</div>
                  <MSPlaidClientProvider
                    includeBalances={false}
                    companyId={company.id}
                  >
                    <PaymentMethod
                      companyId={company.id}
                      stripeCustomerId={company.stripeCustomerId}
                      maxWidth={780}
                    />
                  </MSPlaidClientProvider>
                </>
              </FlagOption>
            </FeatureFlag>
          )}
        </>
      )}
    </div>
  );
};

export default IntegrationsPage;
