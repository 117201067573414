export enum GraphCmsQuestionIdEnum {
  FOUNDED_AFTER_FEB_2020 = 'cknqftv9civus0d17q2r2jfh7',
  GREATER_THAN_FIVE_MIL_RECEIPTS = 'ckgij1soo0zrv0a74xmmxz35s',
  LESS_THAN_ONE_MIL_RECEIPTS = 'cknqfuqw8itl10c323fzmlsvd',
  YEAR_END_EMPLOYEE_COUNT = 'cknqfy4t4ity20c73qsrczwg0',
  EXPECTED_TO_PAY_INCOME_TAX = 'cknqfvd9siw390d17qtxk3p5i',
  MOST_EMPLOYEE_STATES = 'cknqfwsywitsr0c73uauulfqf',
  CREDITS_SELECTED = 'cknqfynbsiwir0d17bszdl980',
  REMOTE_OR_DISPLACED_EMPLOYEES = 'cknqfuieoitiw0c32p4s5qsj0',
  AVG_MONTHLY_REV_LESS_THAN_80K_2020 = 'cko1vgipk8wdp0d23pkr1p0p7',
  AVG_MONTHLY_REV_LESS_THAN_80K = 'cko1vhpx490tf0b19798mfdl5',
  OFFER_PAID_SICK_LEAVE = 'ckovte1xs1bui0b20digr2k5x',
  HOURS_OFF_FOR_VACCINE = 'ckp2zvvywwda00a223223c0bm',
  DAYS_OFF_FOR_VACCINE = 'ckp2zw3oowe2o0d73523s1qqw',
  HAS_GENERATED_REVENUE = 'ckgij0c7s0zny0a74fn7ljlc6',
  FIRST_YEAR_WITH_INCOME = 'ckgij1tgg0zrh0a67jclfz173',
  GREATER_THAN_FIVE_MIL_RECEPTS_NEXT_YEAR = 'ckif2zhwg01ny0a251aqjn88a',
  OWE_FED_TAXES_THIS_YEAR = 'ckgij1qdc0zr90a26pxicagh4',
  CLAIMED_RD_CREDIT_BEFORE = 'ckgij2hdk0ztt0a749q6qaw0y',
  BUSINESS_DESCRIPTION = 'ckgij2sy80zu70b176xwsokxx',
  RD_ACTIVITY_CONTRIBUTORS = 'ckgij3fbs0zw30a74m7wdhred',
  EMPLOYEES_WORKING_IN_US = 'ckgijbai810hf0b17qvzt3hmr',
  CONTRACTORS_WORKING_IN_US = 'ckgijchps10kt0a26laldsm1m',
  OWN_IP_FROM_CONTRACTORS = 'ckgijcons10la0a67usxp1v0i',
  RD_ACTIVITIES_FOR_INTERNAL_ONLY = 'ckgijgfow10w40a672g9k66yf',
  RECEIVED_GOVERNMENT_FUNDING = 'ckgijgo6g10xa0a74oocdk38r',
  WHERE_WAS_GRANT_FROM = 'ckrmejwg05am00b202mvbu0cb',
  HOW_MUCH_OF_RD_ACTIVITIES_DOES_GRANT_COVER = 'ckrmel1c856ii0c65myedztzh',
  PERFORM_RD_ACTIVITIES_UNDER_CONTRACT = 'ckgijgxfs10xo0a26t7f0t8yd',
  CONTRACT_TERMS_FOR_RD_ACTIVITIES_UNDER_CONTRACT = 'ckgijia1s111v0a742ap43xq8',
  RETAIN_RIGHTS_TO_IP_DEVELOPED_UNDER_CONTRACT = 'ckgijifg8111o0a673g3tz92k',
  COMPANY_SUBSIDIARY_OF_ANOTHER_COMPANY = 'ckgijilmg112e0a679qf0obk8',
  RD_IMPROVE_PRODUCT_OR_SERVICE = 'ckqmpk0qgkkjq0c75ys8010ob', // q1
  WHAT_TYPE_OF_TECHNICAL_UNCERTAINTY = 'ckqmtwf4wlvob0b27lvvrk6sr', //q2
  FOLLOW_PROCESS_OF_EXPERIMENTATION = 'ckqmpgdk8k8an0a67so892y08', //q3
  WHAT_KINDS_OF_TECHNOLOGICAL_PROJECTS = 'ckqla1uqg7dgv0c69yrv1vx1n', // q4
  MONEY_IN_BANK = 'ckrl2zcfssqpx0c65zlk3m2ez',
  MONTHLY_BURN = 'ckrl30fsgsumx0b20tw1s5ump',
  DEPOSIT_INTO_HIGH_YIELD = 'ckrl30zuost4q0c75qrhjjynt',
  WHAT_BENEFITS = 'ckrl32y2gsr6x0c65ij9lhvyc',
  TARGET_SALARY_INCREASE = 'ckrl33lzksv0r0b20xu3i85yw',
  SPEND_PER_EMPLOYEE = 'ckrljij4wx2dq0c75p9im3o1w',
  TIME_TRACKING = 'ckrl4a7xst6890c654vcqq3h7',
  ACQUIRED_ANOTHER_COMPANY_OR_HAS_SUBSIDIARIES = 'ckrmh2stk5y7i0c75myozp6io',
  YEAR_ACQUIRED_COMPANY_FOUNDED = 'ckrmlifqg70ci0e76timljstg',
  SAAS_SPEND = 'ckrp0ff942pah0c80d9lkuh0w',
  TARGET_BONUS_INCREASE = 'ckrp0f24w2p7s0c80784tsfpu',
  NAME_OF_ACCOUNTANT = 'ckrp0x2a82tqb0b26x2b71r6f',
  ACCOUNTANT_NAME = 'ckrp0x2a82tqb0b26x2b71r6f',
  ACCOUNTANT_EMAIL = 'ckrp0xj9c2ttw0b2690946a4q',
  CANNOT_FIND_PAYROLL_IN_PAYROLL_SYSTEM = 'cks51zl00534m0b74uais4eh9',
  MANUAL_PAYROLL_ROUGH_ESTIMATE = 'cks6cadmo08i10b235mggoe69',
  WE_NOTICED_A_GAP_IN_PAYROLL = 'cks5a433c66570b809qh0sana',
  PERCENT_BUILDING_OWN_PRODUCTS = 'ckspl7kmwufsh0c29f28fedw2',
  HAS_TRANSITIONED_CORPORATE_ENTITY_TYPE = 'ckre0sk74ibur0a29rl3kr0ce',
  ENTITY_TYPE_TRANSITION_YEAR = 'ckrnlv6pccqrz0c655rkzqd4e',
  ORIGINAL_ENTITY_FOUNDING_YEAR = 'ckrp4uz2w0vbr0b616tmlgf3u',
  NEW_SOFTWARE_TO_BUY_NEXT_YEAR = 'cktipgbs806c30b74hrzsa6vd',
  GREATER_THAN_FIVE_MIL_IN_ASSETS = 'cktipkhh4073y0c79ebovzmuh',
  ARE_YOU_IN_CONTROL_GROUP = 'cktj27lqg1zw40b705t0n2zrk',
  FILED_CONSOLIDATED_RETURNS = 'ckwv6geewj7zr0a73seu8jpez',
  ANYONE_OWN_MORE_THAN_50PERCENT = 'ckwv6cv3kj6a30a73w6wqa8zl',
  IS_CONTROL_GROUP_RD_IN_US = 'cktj2jgfk1zmz0a20yw9jtau9',
  YEAR_CONTROL_GROUP_COMPANY_FOUNDED = 'cktj2k7fs21050c78z2femjtm',
  APPROXIMATE_SAAS_SPEND = 'ckuoj46io392y0a78do3lh4p8',
  WHO_MANAGES_SAAS_NAME = 'ckuoj5f9s37f60b18x3ml26p9',
  WHO_MANAGES_SAAS_EMAIL = 'ckuoj6lpk39dk0a78mh5gl806',
  TOP_THREE_SAAS_OPTIONS = 'ckuoj894g3ag50b74ihh6fkm4',
  CURRENT_NUMBER_OF_EMPLOYEES = 'ckuycrjzs39pe09693tgieq0u',
  EXPECTED_NUMBER_OF_EMPLOYEES_IN_12_MONTHS = 'ckuycscjk3af70a74wyf86ugw',
  PAYROLL_GAP_QUESTION_ID = 'cl0b902dlnp7u0bmx7a1gziuu',
  PERCENTAGE_OF_COMPANY_TECHNICAL = 'cl5a4e3m97hjo0dlw564y9rh0',
  ESTIMATED_EMPLOYEES_PAYROLL_EXPENSES_LAST_MONTH = 'cl5d28p45q30w0cmx86605boy',
  ESTIMATED_CONTRACTOR_PAYROLL_EXPENSES_LAST_MONTH = 'cl5bm88q98ulb0bmz69j92qko',
  FIRST_MONTH_OF_PAYROLL = 'cl5a3dh5r6fp30dlw59tf98kk',
  CURRENT_YEAR_EMPLOYEE_COUNT = 'cl5a4mdul7eg20bmzovgixxad',
  NEXT_YEAR_EXPECTED_EMPLOYEE_COUNT = 'cl5a4pvg175q30clv04e6fwfl',
  EMPLOY_US_BASED_PEOPLE = 'cl59yak2n0dut0bmz7cagme0g',
  HOW_MUCH_SPEND_IN_FISCAL_TAX_YEAR = 'clcj6hq5e07mo09ls7ajkuaw3',
  GROSS_RECEIPT_PRIOR_TO_FIVE_YEARS = 'clcj6m5vp08sr0an1brm52j9j',
  HAS_YOUR_COMPANY_RECEIVED_GRANTS = 'cldatr3eq8ww10bmyc8y0kx1o',
  DO_ALL_STATEMENTS_APPLY = 'clcj6qgd40csm0bln83wmnfzj',
  EXPECT_SOMEONE_TO_OWN_ANOTHER_COMPANY = 'cldattuc090710amvm32k691q',
  WHAT_PERCENT_CLOUD_RD = 'ckx9e1erc8o9u0b68pmebv48e',
  SPEND_ANY_AMOUNT_CLOUD = 'ckx9dblqw87bd0b717f2xjng7',
  SPEND_ANY_AMOUNT_SUPPLIES = 'ckx9e9xuw8rf50d712xnzuove',
  WHAT_PERCENT_SUPPLIES_RD = 'ckx9eqsc88zrm0b71ihhromoi',
  ONE_OR_MULTIPLE_PROJECTS = 'ckxgbenbc349s0e286inhai0w',
  WHAT_MONTH_FILE_TAXES = 'clnw9fygsu1as0amxi66h4dlm',
  ALREADY_CLAIMED_ERC = 'clr88zx3t530f0bn2hsjx5lm8',
  DECREASE_IN_REVENUE_DURING_PANDEMIC = 'clr89l0te0qwe0blpu7ce7gm3',
  HOW_DID_YOU_HEAR_ABOUT_MST = 'clsvwnecw131p07n47vkzi3pi',
  FOUNDED_YEAR = 'clrn0lq4jivgh0blt72j69a0u',
  MAIN_PROJECT_NAME = 'ckxgcu3xs3sn60b76hzgr66q1',
  CREDIT_CLAIM_YEAR = 'clv196l5b2svd07k4t7v474n5',
  RETAIN_GRANT_RESEARCH_RIGHTS = 'clwidhyfm4axg07mzobdohuhx',
  FINANCIAL_LOSS_RISK = 'clwjh4fjbh1zk07n2uits2xmq',
  GRANT_CONTRACT_AVAILABLE = 'clwsbm7h9c44s06lgos7z2res',
  SUBSIDIARIES_PERFORM_RD = 'clx565yof08wg07n64gzsmlo1',
  ACQUIRIES_PERFORM_RD = 'ckx6f657scbew0b7334ac1xsk',
  SUBSIDIARY_QRE_PERCENTAGE = 'clxcbji6z62qj07n2re11vuio',
  ACQUISITION_QRE_PERCENTAGE = 'clxjdk3qy2bk007lkxmeg0eqm',
  SUBSIDIARY_TAXYEAR_MINUS_1_QRE_PERCENT_OF_TAXYEAR = 'clxdrdewtov5k07n1skp5fakr',
  SUBSIDIARY_TAXYEAR_MINUS_2_QRE_PERCENT_OF_TAXYEAR = 'clxdvkbgb4dat07myvqnfyj1s',
  SUBSIDIARY_TAXYEAR_MINUS_3_QRE_PERCENT_OF_TAXYEAR = 'clxdvlkdkv8tx07n1n5q8cz5a',
  SUBSIDIARY_TAXYEAR_MINUS_4_QRE_PERCENT_OF_TAXYEAR = 'clxdvm8tvvbgy07n1zw2wn9i6',
  SUBSIDIARY_TAXYEAR_MINUS_5_QRE_PERCENT_OF_TAXYEAR = 'clxdvmns0ve5q07n1xrukm9kx',

  ACQUIREE_TAXYEAR_MINUS_1_QRE_PERCENT_OF_TAXYEAR = 'clxjgpgd9xvf407n05rc36sdt',
  ACQUIREE_TAXYEAR_MINUS_2_QRE_PERCENT_OF_TAXYEAR = 'clxjgt6mxya4307n06hk31sgn',
  ACQUIREE_TAXYEAR_MINUS_3_QRE_PERCENT_OF_TAXYEAR = 'clxjgu94cyf5807n095rxh3vg',
  ACQUIREE_TAXYEAR_MINUS_4_QRE_PERCENT_OF_TAXYEAR = 'clxjgviol5x5107na1yi4mnak',
  ACQUIREE_TAXYEAR_MINUS_5_QRE_PERCENT_OF_TAXYEAR = 'clxjgwnt9ynis07n07zw3mwy9',

  GROSS_RECEIPTS_2016 = 'clrqm3ktf3nf50bn4yvk76k1y',
  GROSS_RECEIPTS_2017 = 'ckxql3ycg9k3n0d26gq1662ec',
  GROSS_RECEIPTS_2018 = 'ckxql4j6g9k850d26eh71eu2p',
  GROSS_RECEIPTS_2019 = 'ckxql51p49jc30b30hyj2p1q4',
  GROSS_RECEIPTS_2020 = 'ckxql5hwg9kh90c27ikqpn2qf',
  GROSS_RECEIPTS_2021 = 'clbbxdkkedam30amx01kbjnkc',
  GROSS_RECEIPTS_2022 = 'clqzqwd9kekkx0bmz0us7vqd3',
  FEDERAL_INCOME_TAX_RETURN_2016 = 'ckxot5f54tcmy0b76eoxr2h29',
  FEDERAL_INCOME_TAX_RETURN_2017 = 'ckxot62agteop0g298vb8xvjz',
  FEDERAL_INCOME_TAX_RETURN_2018 = 'ckxot6kt4tagg0e28hm3biuv3',
  FEDERAL_INCOME_TAX_RETURN_2019 = 'ckxot708ote3r0b69c58w0e2y',
  FEDERAL_INCOME_TAX_RETURN_2020 = 'ckxouznegtxst0b69itqf8f68',
  FEDERAL_INCOME_TAX_RETURN_2021 = 'clbbxay3wdbmx0bn17ia9fapr',
  FEDERAL_INCOME_TAX_RETURN_2022 = 'clqzqtrux389b0blmbscg49jx',

  GROSS_RECEIPTS_TAXYEAR_MINUS_1 = 'clxf5rhgokk2407lq6jnt5vhm',
  GROSS_RECEIPTS_TAXYEAR_MINUS_2 = 'clxf5s2z90yt507li9qywzwwn',
  GROSS_RECEIPTS_TAXYEAR_MINUS_3 = 'clxf5sg5p0z1s07li26lhj6ho',
  GROSS_RECEIPTS_TAXYEAR_MINUS_4 = 'clxf5sqct0z6c07li30vrak8n',
  GROSS_RECEIPTS_TAXYEAR_MINUS_5 = 'clxf5t0dvkld907lqkwypy0s0',
  FEDERAL_INCOME_TAX_RETURN_TAXYEAR_MINUS_1 = 'clxf5n5yzpkh207n0jbzywngb',
  FEDERAL_INCOME_TAX_RETURN_TAXYEAR_MINUS_2 = 'clxf5oml7kga307lqy3ls1yb8',
  FEDERAL_INCOME_TAX_RETURN_TAXYEAR_MINUS_3 = 'clxf5pm44ppvg07n0u4tj6di4',
  FEDERAL_INCOME_TAX_RETURN_TAXYEAR_MINUS_4 = 'clxf5q0bepq6907n0jalufm1i',
  FEDERAL_INCOME_TAX_RETURN_TAXYEAR_MINUS_5 = 'clxf5qc5f0x8i07lidakce0eb',

  CURRENT_ENTITY_TYPE = 'clx97egpj22te0ilpp7zkrr2r',
  DATE_OF_TRANSITION = 'clx97gumk25w00ilpme3ivemd',
  FILING_2_SHORT_YEAR_TAX_RETURNS = 'clx9jfgpe4mi307llgqxi2j8n',
  SHORT_YEAR_FILE_UPLOAD = 'clxbaza43dbcr07n216dq4moj',
}

export enum GraphCmsAnswerIdEnum {
  EMPLOYEES_ONLY = 'ckgij6xvc104m0a6784314bgr',
  MULTIPLE_PROJECTS = 'ckxgbea7435dw0b69d60ymfmv',
}

export const GraphCmsQuestionIdToAnswers: Partial<
  Record<GraphCmsQuestionIdEnum, any>
> = {
  [GraphCmsQuestionIdEnum.ACQUIRIES_PERFORM_RD]: {
    YES: 'ckx6f98bscfwl0c66350ln4rg',
  },
  [GraphCmsQuestionIdEnum.SUBSIDIARIES_PERFORM_RD]: {
    YES: 'clx565kpm08of07n6cy3zp7mw',
  },
  [GraphCmsQuestionIdEnum.GRANT_CONTRACT_AVAILABLE]: {
    NO: 'clwsboux665fx07n0u6mzprl6',
    YES: 'clwsbofrucdy406lgxeaakj6r',
  },
  [GraphCmsQuestionIdEnum.FINANCIAL_LOSS_RISK]: {
    NO: 'clwjh54jhh3pz07n27tssno04',
    YES: 'clwjh4wf00eoa07n29rjo514q',
  },
  [GraphCmsQuestionIdEnum.RETAIN_GRANT_RESEARCH_RIGHTS]: {
    NO: 'clwidj8hn3bj407mwe806jbfy',
  },
  [GraphCmsQuestionIdEnum.CREDIT_CLAIM_YEAR]: {
    2023: 'clv19atzo30q407k4geztb6ss',
    2024: 'clv19c99e56pk07ltv6c93jmq',
  },
  [GraphCmsQuestionIdEnum.FOUNDED_AFTER_FEB_2020]: {
    YES: 'cknqftea8itam0c73g1e47m27',
    NO: 'cknqftp34ipkn0c74e2fig7nl',
  },
  [GraphCmsQuestionIdEnum.MOST_EMPLOYEE_STATES]: {
    NONE: 'cl27mqa92t6zc0bn5xt5rie94',
    ARIZONA: 'cl27mpjsxt4r00emzfyjvxedd',
    CALIFORNIA: 'cl27mprhdt6ud0bn50r9ncu5v',
    GEORGIA: 'cl27mpyimt6w70bn5g3pfwt8f',
    MASSACHUSETTS: 'cl27mq4xitc5p0bn02mdwv9en',
  },
  [GraphCmsQuestionIdEnum.CREDITS_SELECTED]: {
    NONE: 'cknqfyh5kiu4k0c328jngiq46',
  },
  [GraphCmsQuestionIdEnum.ACQUIRED_ANOTHER_COMPANY_OR_HAS_SUBSIDIARIES]: {
    YES_ESTABLISHED_SUBSIDIARY: 'clx55j7tolmqz07lqd7qyxutp',
    YES_FULL_ACQUISITION: 'clx55k9vy813g07myfbmbl1fp',
    YES_ASSETS_ONLY_NO_EMPLOYEES_OR_CUSTOMERS: 'ckrml1b806wn10c65z9yaupug',
    NO: 'ckrml1qnk6zn10b20sc0dtz6w',
    YES_ACQUIRED_FULL_BUSINESS: 'ckrml07vc6zbt0b207aazw847', // deprecated
    UNSURE: 'ckrml29y06zrj0b20o6t5i0yz', // deprecate
  },
  [GraphCmsQuestionIdEnum.RD_ACTIVITY_CONTRIBUTORS]: {
    EMPLOYEES_ONLY: 'ckgij6xvc104m0a6784314bgr',
    EMPLOYEES_AND_CONTRACTORS: 'ckgij75l4105m0a260jgwtlew',
    CONTRACTORS_ONLY: 'ckgij7brc10630a67g31009me',
  },
  [GraphCmsQuestionIdEnum.HAS_TRANSITIONED_CORPORATE_ENTITY_TYPE]: {
    YES_KEPT_SAME_EIN: 'ckre0qoaoibtf0b267dnobc91',
    YES_CHANGED_EIN: 'ckre0qz3kibuv0b265jck64ac',
    NO: 'ckre0r7l4ibof0a29vvt8t05y',
    UNSURE: 'ckre0rczkicac0d76f84zppc4',
  },
  [GraphCmsQuestionIdEnum.WHAT_TYPE_OF_TECHNICAL_UNCERTAINTY]: {
    UNCLEAR_DESIGNS_OR_ALGORITHMS: 'ckqmtve3kln0n0a67vimajim2',
    UNCLEAR_PROCESSES_OR_METHODOLOGIES: 'ckqmtvl1klvft0b27jxv3x9k0',
    CAPABILITIES_NEEDED_TO_MEET_OUTCOME: 'ckqmtvrzkm3ul0c69a93x754m',
    OTHER_TECHNICAL_UNCERTAINTIES: 'ckqmtw2sgln5n0a67jjldssfx',
    NONE: 'ckqmtw9qgly2v0c75i51fo169',
  },
  [GraphCmsQuestionIdEnum.RD_IMPROVE_PRODUCT_OR_SERVICE]: {
    DESIGN_NEW_FEATURES: 'ckqmpj53kkkd80c751qrd8837',
    IMPROVE_PERFORMANCe: 'ckqmpjc1kk9ed0a67pfxlbaqy',
    IMPROVE_INTERNAL_PROCESSES: 'ckqmpjizkkg2a0b27oj3ylevo',
    MISC_CUSTOMER_EXP_IMPROVEMENTS: 'ckqmpjpxkkg460b27s3ux6wsi',
    NONE: 'ckqmpjw3skg590b27kl3295mt',
  },
  [GraphCmsQuestionIdEnum.FOLLOW_PROCESS_OF_EXPERIMENTATION]: {
    TEST_PROTOTYPES: 'ckqmpeekokizh0c75uxdmmmqx',
    RUN_LAB_TRIALS: 'ckqmpesgokeu60b27vs69iy52',
    TEST_CONCEPTS_IN_INTERVIEWS: 'ckqmpf1q0kewi0b27cs3a8oyk',
    RUN_AB_TESTS: 'ckqmpf1q0kewi0b27cs3a8oyk',
    RUN_SCIENTIFIC_EXPERIMENTS: 'ckqmpfeu8kez00b27ayoe9cq6',
    MISC_EMPLOYEE_TASKS_AND_LEARNINGS: 'ckqmpfmk0kjb30c755libke6k',
    NONE: 'ckqmpg0g0kjfi0c75y9eh85b4',
  },
  [GraphCmsQuestionIdEnum.WHAT_KINDS_OF_TECHNOLOGICAL_PROJECTS]: {
    NEW_SOFTWARE_PLATFORM_OR_APP: 'ckql9yw9479ti0c75ffl66d7x',
    NEW_ALGORITHMS: 'ckql9z7ts7cgy0c69nc7yz6zt',
    NEW_PHYSICAL_PRODUCT: 'ckql9zimo768u0b27xe3gpgq7',
    NEW_PHARMA_OR_BIOTECH_VENTURE: 'ckql9zwio73820a67cevb1hyb',
    OTHER_NOVEL_SCIENTIFIC_OR_ENGINEERING_PRODUCTS: 'ckqla083c73a30a67z884o89t',
    NONE: 'ckqla0pu876lr0b27uuqlet2m',
  },
  [GraphCmsQuestionIdEnum.YEAR_ACQUIRED_COMPANY_FOUNDED]: {
    YEAR: 'ckrmlltnc72jb0b20lexg9bhn',
    UNSURE: 'ckrmmcsbs75zi0c75tcka6wdw',
  },
  [GraphCmsQuestionIdEnum.WHAT_BENEFITS]: {
    NO_MATCH_401K: 'ckrl32lq0stg30e76phddjchc',
    FSA: 'ckrl32vr4suxu0b20zyo57suh',
    NONE: 'ckrljdbmwx21t0c754jxft12w',
  },
  [GraphCmsQuestionIdEnum.CANNOT_FIND_PAYROLL_IN_PAYROLL_SYSTEM]: {
    CHANGED_PAYROLL_SYSTEM: 'cks51ze2053pc0b80wcpa3oru',
    HAVENT_PAID_EMPLOYEES_YET: 'cks5200fk536s0b74mjasgp7h',
    NO_PERMISSION_TO_PAYROLL_SOFTWARE: 'cks52085c53fm0c75p89ph288',
    NONE_OF_THE_ABOVE: 'cks520heo53h40c75x6l9iymc',
  },
  [GraphCmsQuestionIdEnum.EMPLOYEES_WORKING_IN_US]: {
    EVERYONE_IN_US: 'ckgijaml410fg0a266bsh8dmj',
    SOME_IN_US: 'ckgijay5s10gc0b17wposh9ov',
    NOBODY_IN_US: 'ckgijb53s10gy0b176og20xs9',
  },
  [GraphCmsQuestionIdEnum.CONTRACTORS_WORKING_IN_US]: {
    ENTIRELY_IN_US: 'ckgijc32010jd0a67gv9elrcu',
    SOME_NOT_IN_US: 'ckgijcbjk10km0a74b31ar26x',
    NONE_IN_US: 'ckgijcg6810km0b171dgrr59h',
  },
  [GraphCmsQuestionIdEnum.OWN_IP_FROM_CONTRACTORS]: {
    OWN_ALL_IP: 'ckgs2p5ig3a2k0b25oyby3bla',
    OWN_SOME_IP: 'ckif4xzpk070t0a145u2o09ht',
    OWN_NONE: 'ckgs2p7ts3a2d0b27uqgr73qk',
  },
  [GraphCmsQuestionIdEnum.RECEIVED_GOVERNMENT_FUNDING]: {
    YES: 'ckgs2ph343a2m09796luptk1m',
    NO: 'ckgs2pk683a360b27gwd514jq',
    UNSURE: 'ckgs2po143a3g0b27oy6xjptl',
  },
  [GraphCmsQuestionIdEnum.WHERE_WAS_GRANT_FROM]: {
    NIH: 'ckrmeg2bs54y10c650e2g87yl',
    DOD: 'ckrmegatc54zz0c65takvtoxs',
    NSF: 'ckrmegij459p70b20aa8g5f8c',
    SBA: 'ckrmegopc573r0c755f4qw595',
    DOE: 'ckrmeh44w57gr0e76ekc7kgia',
    SBIR: 'ckrmeh9jc579h0c75syoc0cjy',
    OTHER_FEDERAL_GOV_AGENCY: 'ckrmehjkg55lu0c655r2xkeqo',
    STATE_OR_LOCAL_GOV_AGENCY: 'ckrmehs2057lk0e769sjjucfq',
    PRIVATE_FOUNDATION: 'ckrmehz0055qt0c65dekxg4ko',
    PRIVATE_COMPANY: 'ckrmei7hk5a8r0b20bnaudaxq',
    UNIVERSITY: 'ckrmeicw057q40e76qkfad39c',
    PPP_LOAN: 'ckrmeimx457rd0e76h3fuewso',
    OTHER: 'ckrmeit3c5ac70b206x69fqa3',
  },
  [GraphCmsQuestionIdEnum.WHAT_BENEFITS]: {
    NO_MATCH_401K: 'ckrl32lq0stg30e76phddjchc',
    FSA: 'ckrl32vr4suxu0b20zyo57suh',
    NONE: 'ckrljdbmwx21t0c754jxft12w',
  },
  [GraphCmsQuestionIdEnum.WE_NOTICED_A_GAP_IN_PAYROLL]: {
    CHANGED_PAYROLL_SYSTEM: 'cks5a276w65wx0b801298jlan',
    BEGAN_PAYROLL_LATE: 'cks5a2irk663u0c75ju1rwf9o',
    HAVENT_RUN_PAYROLL_SYSTEM_IN_AWHILE: 'cks5a2r94665e0c75xcpagyex',
    OTHER: 'cks5a3220663t0b740xxhwdfs',
  },
  [GraphCmsQuestionIdEnum.GREATER_THAN_FIVE_MIL_IN_ASSETS]: {
    YES: 'cktiucqv413cu0b36mniuylw7',
    NO: 'cktiud1o012sq0b70wlhp2hd6',
  },
  [GraphCmsQuestionIdEnum.PAYROLL_GAP_QUESTION_ID]: {
    PAYROLL_GAP_ANSWER_ID_USING_DIFFERENT_PAYROLL_SYSTEM:
      'cl0b8yxjpnsip0dmw9h56bzdv',
    PAYROLL_GAP_ANSWER_ID_DIDNT_ISSUE_PAYMENTS: 'cl0b8z90gnssf0dmwi3v3t113',
    PAYROLL_GAP_ANSWER_ID_DIDNT_USE_A_PAYROLL_SYSTEM:
      'cl0b8zjhdnsyp0dmwxqnxr56x',
    // PAYROLL_GAP_ANSWER_ID_OTHER: 'cl0b8zlubftlc0emv8qdpxr76', COREX-572: Remove 'Other' keeping this as commented out so we have a reference for answer_id if we need to revert
  },
  [GraphCmsQuestionIdEnum.HAS_GENERATED_REVENUE]: {
    YES: 'ckgs2mhu039w70b27myarddrz',
    NO: 'ckgs2mkx439vx0979bklizqux',
  },
  [GraphCmsQuestionIdEnum.PERCENTAGE_OF_COMPANY_TECHNICAL]: {
    LESS_THAN_50_PERCENT: 'cl5a4db056z7e0clv04e1yxmq',
    ROUGHLY_50_PERCENT: 'cl5a4dozb7vw80blr21f2zgxu',
    MORE_THAN_50_PERCENT: 'cl5a4dxll7hgt0dlwq3l0h9pv',
  },
  [GraphCmsQuestionIdEnum.FIRST_MONTH_OF_PAYROLL]: {
    JANUARY: 'cksgarff453z10e7964dazcg3',
    FEBRUARY: 'cksgarnwo547x0b755qki6tn9',
    MARCH: 'cksgas9ig4zrd0c73dc4k6uyw',
    APRIL: 'cksgash88505g0d778pler072',
    MAY: 'cksgasmmo4zty0c73q5wrjka8',
    JUNE: 'cksgasr9c544h0e79v1p0g39t',
    JULY: 'cksgasy7c545f0e79js6lyggk',
    AUGUST: 'cksgat0io54e10b75w13p2cpf',
    SEPTEMBER: 'cksgat3ls50910d77oi2kq2px',
    OCTOBER: 'cksgat88g54f50b75q991aosh',
    NOVEMBER: 'cksgatc3c50ah0d77p86a1wqt',
    DECEMBER: 'cksgatfy850bi0d77tttx23nq',
  },
  [GraphCmsQuestionIdEnum.EMPLOY_US_BASED_PEOPLE]: {
    YES: 'ckgs2q1x43a4k0b27t4lvv6tv',
    NO: 'ckgs2q3go3a5n0a82mg6qwfos',
  },
  [GraphCmsQuestionIdEnum.TIME_TRACKING]: {
    YES: 'ckrl54tsgtn0m0b20bym0248w',
    NO: 'ckrl561rstmku0c75v4fd47pz',
    UNSURE: 'ckrl56dcgtnbr0e768dhui0r4',
  },
  [GraphCmsQuestionIdEnum.FILED_CONSOLIDATED_RETURNS]: {
    YES: 'ckwv6g6p4j7xn0a73vmwe6pdo',
    NO: 'ckwv6gbbsj91s0d25denth5n0',
    UNSURE: 'ckx5c2wq00fto0b737k2adbtm',
  },
  [GraphCmsQuestionIdEnum.ANYONE_OWN_MORE_THAN_50PERCENT]: {
    YES: 'ckwv6clu8j77d0d25oqvxfddh',
    NO: 'ckwv6cr8oj83e0b251u0542ah',
  },
  [GraphCmsQuestionIdEnum.GREATER_THAN_FIVE_MIL_RECEIPTS]: {
    YES: 'ckgs2o0m839zw0b27rpguf8d9',
    NO: 'ckgs2o2xk3a060b25djyo3yrd',
  },
  [GraphCmsQuestionIdEnum.GROSS_RECEIPT_PRIOR_TO_FIVE_YEARS]: {
    YES: 'clcjb0n441g3z0bmwoiu7bokr',
    NO: 'clcjb0urr1g0t0amx1eeso07g',
  },
  [GraphCmsQuestionIdEnum.PERFORM_RD_ACTIVITIES_UNDER_CONTRACT]: {
    BUILD_ONLY_FOR_CLIENTS: 'ckspk1bswbcd80b64tj0zg2pr',
    BUILD_FOR_SELF_AND_CLIENTS: 'ckspk1y6gbced0b649fb3gb0f',
    BUILD_ONLY_FOR_SELF: 'ckspk2ttcbcgv0b64mrky7y3d',
  },
  [GraphCmsQuestionIdEnum.HAS_YOUR_COMPANY_RECEIVED_GRANTS]: {
    YES: 'cldatqz1k8ujq0an1orlezxec',
  },
  [GraphCmsQuestionIdEnum.EXPECT_SOMEONE_TO_OWN_ANOTHER_COMPANY]: {
    YES: 'cldattky68vpv0an1pqnci0yy',
  },
  [GraphCmsQuestionIdEnum.DO_ALL_STATEMENTS_APPLY]: {
    NO: 'clcjk54kv70ut0blhoecdglr7',
  },
  [GraphCmsQuestionIdEnum.SPEND_ANY_AMOUNT_CLOUD]: {
    YES: 'ckx9d2ysg814b0a72anjb0ur9',
  },
  [GraphCmsQuestionIdEnum.SPEND_ANY_AMOUNT_SUPPLIES]: {
    YES: 'ckx9e97mg8qq00b7176wytbwo',
    NO: 'ckx9e9d0w8siw0a72h7ib7a0t',
  },
  [GraphCmsQuestionIdEnum.ALREADY_CLAIMED_ERC]: {
    YES: 'clr89244v2c9q0blp7lys6h1x',
    NO: 'clr892jvn0hmk0blpqjrqnz9a',
    NOT_SURE: 'clr89355o0i120blpgxzlyc4a',
  },
  [GraphCmsQuestionIdEnum.DECREASE_IN_REVENUE_DURING_PANDEMIC]: {
    YES: 'clr89l8mo2ls70bmymb4vmopf',
    NO: 'clr89lges5dn30bn277i8hq76',
    NOT_SURE: 'clr89lp9o5dra0bn2ac38ist5',
  },
  [GraphCmsQuestionIdEnum.CURRENT_ENTITY_TYPE]: {
    C_CORP: 'clx97d3t2xgpv07n6nhdmrsfl',
    S_CORP: 'clx97dewx1z7a0ilpr28vxzoz',
    PARTNERSHIP: 'clx97douf1zly0ilpvzorceo9',
    LLC: 'clx97dyi91zx40ilpvrthits0',
  },
  [GraphCmsQuestionIdEnum.FILING_2_SHORT_YEAR_TAX_RETURNS]: {
    YES: 'clx9jig404otf07llyeiogwvs',
    NO: 'clx9jimj44ow607llhks4tyke',
    NOT_SURE: 'clx9jivrm4p1g07lldg60ke0m',
  },
};

export enum GraphCmsFaqGroupIdEnum {
  HIGH_YIELD_ACCOUNT_DASHBOARD = 'cks7vmpe819dw0c313erc833n',
}

export enum GraphCmsAnswerTypeEnum {
  TEXT = 'text',
  TEXT_AREA = 'text_area',
  NUMBER = 'number',
  CURRENCY = 'currency',
  PERCENTAGE = 'percentage',
  MULTIPLE_SELECT = 'multiple_select',
  MULTIPLE_CHOICE = 'multiple_choice',
  YES_OR_NO = 'yes_or_no',
  YES_NO_OR_UNKNOWN = 'yes_no_or_unknown',
}

export enum QuestionTypeEnum {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  NUMBER = 'number',
  CHIP = 'chip',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  NUMBER_CHECKBOX = 'number_checkbox',
}

export enum CompanyDetailsGraphCmsQuestionIds {
  REVIEW_QUALIFICATION_QUESTIONS = 'ckx97z03s5hv20d71bjp6ilo9',
  TAX_INFO_FILE_UPLOAD = 'ckwv6krtkj92h0a735iyr5k1k',
  TAX_INFO_MANUAL = 'ckxql03gg9il10d26o9zjvojc',
  TAX_INFO_MANUAL_QRE_2023 = 'clqzv5md077030blm52hvl2bl',
  OTHER_CREDIT_CLAIMED = 'ckwwiaik0wkxy0b2510fkt903',
  OTHER_CREDITS_CLAIMED_AMOUNT = 'ckwygkv5ceh990c66ag3cuh5p',
  GA_RD_GROSS_RECIEPTS = 'ckx6nggewh6cp0c66min9jo39',
  NAICS_CODE = 'ckwv9ugpck8gn0e194z9jcodo',
  PRIOR_YEARS_RESEARCH_SPEND = 'ckxz4gjyo4xsz0b30wy8ivjcl',
}

export enum ManualTaxInfoGraphCmsQuestionIds {
  GROSS_RECEIPTS_2016 = 'ckxql2r4w9i940b30t92xv3dx',
  GROSS_RECEIPTS_2017 = 'ckxql3ycg9k3n0d26gq1662ec',
  GROSS_RECEIPTS_2018 = 'ckxql4j6g9k850d26eh71eu2p',
  GROSS_RECEIPTS_2019 = 'ckxql51p49jc30b30hyj2p1q4',
  GROSS_RECEIPTS_2020 = 'ckxql5hwg9kh90c27ikqpn2qf',
  GROSS_RECEIPTS_2021 = 'clbbxdkkedam30amx01kbjnkc',
  GROSS_RECEIPTS_2022 = 'clqzqwd9kekkx0bmz0us7vqd3',
}

export enum UploadTaxInfoGraphCmsQuestionIds {
  TAX_FORMS_2016 = 'ckxot5f54tcmy0b76eoxr2h29',
  TAX_FORMS_2017 = 'ckxot62agteop0g298vb8xvjz',
  TAX_FORMS_2018 = 'ckxot6kt4tagg0e28hm3biuv3',
  TAX_FORMS_2019 = 'ckxot708ote3r0b69c58w0e2y',
  TAX_FORMS_2020 = 'ckxouznegtxst0b69itqf8f68',
  TAX_FORMS_2021 = 'clbbxay3wdbmx0bn17ia9fapr',
  TAX_FORMS_2022 = 'clqzqtrux389b0blmbscg49jx',
}

export enum ResearchTimeGraphCmsQuestionIds {
  RESEARCH_2017 = 'clbejseb53e230amrbxjatl46',
  RESEARCH_2018 = 'clbeppggp4a6e0amrdq1amvhq',
  RESEARCH_2019 = 'clbepqea846z30amxj7lt1deb',
  RESEARCH_2020 = 'clbepqw6t4aad0amreg6pgyg0',
  RESEARCH_2021 = 'clbeprnvc4cfw0blndjru54vf',
  RESEARCH_2018_VS_2023 = 'clqzutptg6wbl0blmfhnbijrk',
  RESEARCH_2019_VS_2023 = 'clqzuth0x6vvt0blmvuazsnpc',
  RESEARCH_2020_VS_2023 = 'clqzut6te0s770amtn82i5up9',
  RESEARCH_2021_VS_2023 = 'clqzusz7w0rvd0amtd10j0y0r',
  RESEARCH_2022_VS_2023 = 'clqzusm156ux20blm7abrwpqy',
}

export enum AZGrossReceiptPortionQuestionIds {
  PORTION_2017 = 'ckxj5f5yoxpz60e28ymbw8xdy',
  PORTION_2018 = 'ckxj5gbmoxpa00b76uxhbz36p',
  PORTION_2019 = 'ckxj5gmfkxqmw0b69yrtjcps0',
  PORTION_2020 = 'ckxj5gux4xqef0e28a0iczypi',
  PORTION_2021 = 'clr13uev874cq0bn0wd7hbych',
  PORTION_2022 = 'clr13unyv0lwk0blmka20thp9',
}

export enum GAGrossReceiptPortionQuestionIds {
  PORTION_2018 = 'ckxhywjc8nbkr0b76j9p640f5',
  PORTION_2019 = 'ckxhywvoonca20b76vq3sbv9w',
  PORTION_2020 = 'ckxhyx5psnc110b69q01yovax',
  PORTION_2021 = 'cl0khr7amhev309n0svgd9c2c',
  PORTION_2022 = 'clbbvugtpd78e0bn1fyqnzuc1',
  PORTION_2023 = 'clr17x39h432s0blmpync5c7n',
}

export const taxInfoQuestionIds = [
  {
    manualQuestionId: ManualTaxInfoGraphCmsQuestionIds.GROSS_RECEIPTS_2016,
    uploadQuestionId: UploadTaxInfoGraphCmsQuestionIds.TAX_FORMS_2016,
    researchQuestionId: '',
    researchQuestion2023Id: '',
    azGrossReceiptPortionQuestionId: '',
    gaGrossReceiptPortionQuestionId: '',
    year: 2016,
  },
  {
    manualQuestionId: ManualTaxInfoGraphCmsQuestionIds.GROSS_RECEIPTS_2017,
    uploadQuestionId: UploadTaxInfoGraphCmsQuestionIds.TAX_FORMS_2017,
    researchQuestionId: ResearchTimeGraphCmsQuestionIds.RESEARCH_2017,
    researchQuestion2023Id: '',
    azGrossReceiptPortionQuestionId:
      AZGrossReceiptPortionQuestionIds.PORTION_2017,
    gaGrossReceiptPortionQuestionId: '',
    year: 2017,
  },
  {
    manualQuestionId: ManualTaxInfoGraphCmsQuestionIds.GROSS_RECEIPTS_2018,
    uploadQuestionId: UploadTaxInfoGraphCmsQuestionIds.TAX_FORMS_2018,
    researchQuestionId: ResearchTimeGraphCmsQuestionIds.RESEARCH_2018,
    researchQuestion2023Id:
      ResearchTimeGraphCmsQuestionIds.RESEARCH_2018_VS_2023,
    azGrossReceiptPortionQuestionId:
      AZGrossReceiptPortionQuestionIds.PORTION_2018,
    gaGrossReceiptPortionQuestionId:
      GAGrossReceiptPortionQuestionIds.PORTION_2018,
    year: 2018,
  },
  {
    manualQuestionId: ManualTaxInfoGraphCmsQuestionIds.GROSS_RECEIPTS_2019,
    uploadQuestionId: UploadTaxInfoGraphCmsQuestionIds.TAX_FORMS_2019,
    researchQuestionId: ResearchTimeGraphCmsQuestionIds.RESEARCH_2019,
    researchQuestion2023Id:
      ResearchTimeGraphCmsQuestionIds.RESEARCH_2019_VS_2023,
    azGrossReceiptPortionQuestionId:
      AZGrossReceiptPortionQuestionIds.PORTION_2019,
    gaGrossReceiptPortionQuestionId:
      GAGrossReceiptPortionQuestionIds.PORTION_2019,
    year: 2019,
  },
  {
    manualQuestionId: ManualTaxInfoGraphCmsQuestionIds.GROSS_RECEIPTS_2020,
    uploadQuestionId: UploadTaxInfoGraphCmsQuestionIds.TAX_FORMS_2020,
    researchQuestionId: ResearchTimeGraphCmsQuestionIds.RESEARCH_2020,
    researchQuestion2023Id:
      ResearchTimeGraphCmsQuestionIds.RESEARCH_2020_VS_2023,
    azGrossReceiptPortionQuestionId:
      AZGrossReceiptPortionQuestionIds.PORTION_2020,
    gaGrossReceiptPortionQuestionId:
      GAGrossReceiptPortionQuestionIds.PORTION_2020,
    year: 2020,
  },
  {
    manualQuestionId: ManualTaxInfoGraphCmsQuestionIds.GROSS_RECEIPTS_2021,
    uploadQuestionId: UploadTaxInfoGraphCmsQuestionIds.TAX_FORMS_2021,
    researchQuestionId: ResearchTimeGraphCmsQuestionIds.RESEARCH_2021,
    researchQuestion2023Id:
      ResearchTimeGraphCmsQuestionIds.RESEARCH_2021_VS_2023,
    azGrossReceiptPortionQuestionId:
      AZGrossReceiptPortionQuestionIds.PORTION_2021,
    gaGrossReceiptPortionQuestionId:
      GAGrossReceiptPortionQuestionIds.PORTION_2021,
    year: 2021,
  },
  {
    manualQuestionId: ManualTaxInfoGraphCmsQuestionIds.GROSS_RECEIPTS_2022,
    uploadQuestionId: UploadTaxInfoGraphCmsQuestionIds.TAX_FORMS_2022,
    researchQuestionId: '',
    researchQuestion2023Id:
      ResearchTimeGraphCmsQuestionIds.RESEARCH_2022_VS_2023,
    azGrossReceiptPortionQuestionId:
      AZGrossReceiptPortionQuestionIds.PORTION_2022,
    gaGrossReceiptPortionQuestionId:
      GAGrossReceiptPortionQuestionIds.PORTION_2022,
    year: 2022,
  },
  {
    manualQuestionId: '',
    uploadQuestionId: '',
    researchQuestionId: '',
    researchQuestion2023Id: '',
    azGrossReceiptPortionQuestionId: '',
    gaGrossReceiptPortionQuestionId:
      GAGrossReceiptPortionQuestionIds.PORTION_2023,
    year: 2023,
  },
];

export enum ToDoCardIdEnum {
  QUALIFY_YOUR_BUSINESS = 'cl80hbtdt2xu80bn0do0ap8vr',
  HOORAY_YOURE_QUALIFIED = 'cl80he1wj2yq00bn0zgy1ynu2',
  HOORAY_STATE_CREDIT = 'cl9eq9roabni00blj7p21q522',
  TAX_YEAR_RD_ASSESSMENT = 'cl80hgjnb2wvq0alsxzkczgrg',
  REVIEW_IN_PROGRESS = 'cl80hiced2y7j0alsg51gxftz',
  CLIENT_REVIEW = 'cl80hjj0y33k50bn0ld2wdxwb',
  USE_YOUR_CREDITS = 'cl80hkvx433du0alis4m5rl0d',
  INVITE_YOUR_TEAM = 'cl80hm0ow2zxj0als0fqhqf90',
  CONNECT_YOUR_PAYROLL = 'cl80hmurf30c70als5dae3o06',
  CONNECT_YOUR_ACCOUNTING = 'cl80hnmnl34tu0ali6wfiue3p',
  UNIFIED_YEA = 'clrqvn3jmbscn0bn1ocuuwode',
  // ERC demo cards ** DO NOT USE **
  ERC_FLOW = 'cll19ge1r6j6u0amznvbx4dpz',
  HEALTHCARE_CREDIT = 'cll1aojhi73880bll9hw9seuw',
  RETIREMENT_CREDIT = 'cll1apsou73zq0bllu8mq4bi2',
  DISABLED_ACCESS_CREDIT = 'cll1art0u7h1g0amz94otxhsg',
}

export enum ToDoCardsCardType {
  QUALIFY_FOR_MAINSTREET = 'qualify_for_mainstreet',
  ACCEPT_ORDER_FORM = 'accept_order_form',
  COMPLETE_RD_ASSESSMENT = 'complete_rd_assessment',
  REVIEW_IN_PROGRESS = 'review_in_progress',
  FILE_WITH_IRS = 'file_with_irs',
  USE_YOUR_CREDITS = 'use_your_credits',
  INVITE_USERS = 'invite_users',
  CONNECT_PAYROLL = 'connect_payroll',
  CONNECT_ACCOUNTING = 'connect_accounting',
}

export interface HygraphDisqualQuestionsInterface {
  question: GraphCmsQuestionIdEnum[] | GraphCmsQuestionIdEnum;
  answer:
    | (typeof GraphCmsQuestionIdToAnswers)[]
    | typeof GraphCmsQuestionIdToAnswers
    | number;
  customer: string;
  internal: string;
}

export const HygraphDisqualQuestions: HygraphDisqualQuestionsInterface[] = [
  {
    question: GraphCmsQuestionIdEnum.EMPLOY_US_BASED_PEOPLE, // EMPLOY_US_BASED_PEOPLE
    answer:
      GraphCmsQuestionIdToAnswers[GraphCmsQuestionIdEnum.EMPLOY_US_BASED_PEOPLE]
        .NO, // No
    customer:
      'The R&D tax credit mandates that you employ or contract US-based people that help develop new products or software.',
    internal:
      'Does not employ or contract US-based people that help develop new products or software',
  },
  {
    question:
      GraphCmsQuestionIdEnum.ACQUIRED_ANOTHER_COMPANY_OR_HAS_SUBSIDIARIES, // ACQUIRED_ANOTHER_COMPANY
    answer:
      GraphCmsQuestionIdToAnswers[
        GraphCmsQuestionIdEnum.ACQUIRED_ANOTHER_COMPANY_OR_HAS_SUBSIDIARIES
      ].YES_ACQUIRED_FULL_BUSINESS, // Yes
    customer:
      'At this time, we do not work with companies that have acquired a full business during the tax year.',
    internal: 'Acquired a full business',
  },
  {
    question: GraphCmsQuestionIdEnum.ENTITY_TYPE_TRANSITION_YEAR, // ENTITY_TYPE_TRANSITION_YEAR
    answer: 2022,
    customer:
      'At this time, we do not work with companies that transitioned their corporate entity type during the tax year.',
    internal: 'Transitioned corporate entity type in 2022',
  },
  {
    question: GraphCmsQuestionIdEnum.TIME_TRACKING, //  TIME_TRACKING
    answer:
      GraphCmsQuestionIdToAnswers[GraphCmsQuestionIdEnum.TIME_TRACKING].YES, // YES
    customer:
      'At this time, we do not work with companies that use time tracking software.',
    internal: 'Uses time tracking software',
  },
  {
    question: GraphCmsQuestionIdEnum.WHERE_WAS_GRANT_FROM, //  WHERE_WAS_GRANT_FROM
    answer: [
      GraphCmsQuestionIdToAnswers[GraphCmsQuestionIdEnum.WHERE_WAS_GRANT_FROM]
        .NIH,
      GraphCmsQuestionIdToAnswers[GraphCmsQuestionIdEnum.WHERE_WAS_GRANT_FROM]
        .DOD,
      GraphCmsQuestionIdToAnswers[GraphCmsQuestionIdEnum.WHERE_WAS_GRANT_FROM]
        .NSF,
      GraphCmsQuestionIdToAnswers[GraphCmsQuestionIdEnum.WHERE_WAS_GRANT_FROM]
        .SBA,
      GraphCmsQuestionIdToAnswers[GraphCmsQuestionIdEnum.WHERE_WAS_GRANT_FROM]
        .DOE,
      GraphCmsQuestionIdToAnswers[GraphCmsQuestionIdEnum.WHERE_WAS_GRANT_FROM]
        .SBIR,
      GraphCmsQuestionIdToAnswers[GraphCmsQuestionIdEnum.WHERE_WAS_GRANT_FROM]
        .OTHER_FEDERAL_GOV_AGENCY,
    ],
    customer:
      'At this time, we do not work with companies that have received a grant to cover their R&D expenses.',
    internal: 'Received grant',
  },
  {
    question: [
      GraphCmsQuestionIdEnum.RD_IMPROVE_PRODUCT_OR_SERVICE,
      GraphCmsQuestionIdEnum.WHAT_TYPE_OF_TECHNICAL_UNCERTAINTY,
      GraphCmsQuestionIdEnum.FOLLOW_PROCESS_OF_EXPERIMENTATION,
      GraphCmsQuestionIdEnum.WHAT_KINDS_OF_TECHNOLOGICAL_PROJECTS,
    ], // 4_PART_TEST_RD_IMPROVE_PRODUCT_OR_SERVICE
    answer: [
      GraphCmsQuestionIdToAnswers[
        GraphCmsQuestionIdEnum.RD_IMPROVE_PRODUCT_OR_SERVICE
      ].NONE,
      GraphCmsQuestionIdToAnswers[
        GraphCmsQuestionIdEnum.WHAT_TYPE_OF_TECHNICAL_UNCERTAINTY
      ].NONE,
      GraphCmsQuestionIdToAnswers[
        GraphCmsQuestionIdEnum.FOLLOW_PROCESS_OF_EXPERIMENTATION
      ].NONE,
      GraphCmsQuestionIdToAnswers[
        GraphCmsQuestionIdEnum.WHAT_KINDS_OF_TECHNOLOGICAL_PROJECTS
      ].NONE,
    ], // NONE
    customer:
      'You indicated that your company does not perform the research and development requirements set forth by the IRS in the four part test.',
    internal: 'Company failed 4 part test',
  },
  {
    question: GraphCmsQuestionIdEnum.PERFORM_RD_ACTIVITIES_UNDER_CONTRACT, // BUILD ON BEHALF OF OTHER
    answer:
      GraphCmsQuestionIdToAnswers[
        GraphCmsQuestionIdEnum.PERFORM_RD_ACTIVITIES_UNDER_CONTRACT
      ].BUILD_ONLY_FOR_CLIENTS, // YES, ENTIRELY
    customer:
      'The IRS designates that you must own the rights to all of your work. Since you build products exclusively for your clients, you are not eligible for the R&D tax credit.',
    internal: 'Builds products exclusively for clients',
  },
  {
    question: GraphCmsQuestionIdEnum.HAS_YOUR_COMPANY_RECEIVED_GRANTS, // HAS RECEIVED GRANTS
    answer:
      GraphCmsQuestionIdToAnswers[
        GraphCmsQuestionIdEnum.HAS_YOUR_COMPANY_RECEIVED_GRANTS
      ].YES, // YES
    customer:
      'Companies that receive a grant to cover their R&D expenses are not eligible for the R&D tax credit.',
    internal: 'Received grant',
  },
  {
    question: [
      GraphCmsQuestionIdEnum.EXPECT_SOMEONE_TO_OWN_ANOTHER_COMPANY,
      GraphCmsQuestionIdEnum.ANYONE_OWN_MORE_THAN_50PERCENT,
    ], // CONTROL GROUP
    answer: [
      GraphCmsQuestionIdToAnswers[
        GraphCmsQuestionIdEnum.EXPECT_SOMEONE_TO_OWN_ANOTHER_COMPANY
      ].YES,
      GraphCmsQuestionIdToAnswers[
        GraphCmsQuestionIdEnum.ANYONE_OWN_MORE_THAN_50PERCENT
      ].YES,
    ], // YES
    customer:
      'At this time we do not work with companies or companies with individuals that have majority stake in other businesses.',
    internal: 'Control group',
  },
  {
    question: GraphCmsQuestionIdEnum.DO_ALL_STATEMENTS_APPLY, // NEW 4-PART TEST QUESTION
    answer:
      GraphCmsQuestionIdToAnswers[
        GraphCmsQuestionIdEnum.DO_ALL_STATEMENTS_APPLY
      ].NO, // NO
    customer:
      'You indicated that your company does not perform the research and development requirements set forth by the IRS in the four part test.',
    internal: 'Company failed 4 part test',
  },
];

export enum ERCQualifyingQuestions {
  UPLOAD_2021_TAX_RETURN = 'cllv60n8d5i820an89lik6mgd',
  SUBQUESTION_2021_TAX_RETURN = 'cllv62odk5jc90an8snh0mi34',
  UPLOAD_Q3_2021 = 'clm8e97wx5s5e0blwa5s8zw1s',
  UPLOAD_Q4_2021 = 'clm8ea69d5sw50blwccijob6q',
  CONFIRM_GENERATE_MORE_THAN_1MILLION_GROSS_RECEIPTS = 'cllv65lbq5n2a0an2gk7okoxc',
  // qualification question id
  CONFIRM_FOUNDED_YEAR = 'clrn0lq4jivgh0blt72j69a0u',
  ORIGINAL_ENTITY_FOUNDED = 'cllp8ahdzfxws0blnpk0f0f88',
  OLDEST_SIMILARITY_OWNED_BUSINESS_FOUNDED = 'cllp8ayj8g3800an097gtawm9',
  OLDEST_ACQUIRED_COMPANY_FOUNDED = 'cllp8bel7g3ow0bmx7rnwpmvd',
  ALL_GOVERNMENT_PROGRAMS_UTILIZED = 'cllp7ybe9fov60bmxt6bpxblm',
  RELATED_DURING_COVID19_CRISIS = 'cllp859xtffty0alm9fxs97nb',
}

export enum ERCQualifyingQuestionAnswers {
  DID_NOT_GENERATE_MORE_THAN_1MILLION_GROSS_RECEIPTS = 'cllv65iw95kj60bn2go6b4esv',
}

export const ERCQualifyingQuestionsToAnswers: Partial<
  Record<ERCQualifyingQuestions, any>
> = {
  [ERCQualifyingQuestions.ALL_GOVERNMENT_PROGRAMS_UTILIZED]: {
    ERC: 'cllp7yud5fozm0bmxhmrcuzss',
    RD: 'cllp7z3k0fdyw0alm9718p0mr',
    SICK_LEAVE: 'cllp7zfmeflnf0blnwr6qrsx5',
    WOTC: 'cllp7ztfqfpft0an07n2fy69m',
    PPP: 'cllp8052ufpk40an0scvjtr15',
    NONE_OF_THE_ABOVE: 'cllp81rv7fmaz0bln01ujbgk8',
  },
  [ERCQualifyingQuestions.RELATED_DURING_COVID19_CRISIS]: {
    NONE_OF_THE_ABOVE: 'cllp87ricfhr20almtsuuprl4',
  },
};

export enum ERCEmployeeDetailsQuestions {
  MAJORITY_OWNER = 'clmglxn6sfdtx0bkbgigkky7m',
  RELATED_TO_MAJORITY_OWNER = 'clmglysir1qc20bllaoukkdge',
}

export enum RetirementPlanCreditQuestions {
  RETIREMENT_PLAN_OFFERED_FOR_TAX_YEAR = 'cltootuv9dbin07n10bi64tii',
  ESTABLISH_QUALIFIED_RETIREMENT_PLAN = 'clmkykgux3ncm0bn11gqyy7ns',
  START_OFFERING_RETIREMENT_PLAN = 'clmkym4l34xb30bn032ke3brs',
  IN_LOOKBACK_YEAR_HOW_MANY_EMPLOYEES = 'cllp7ww8hfkxa0bln4zp1q1vw',
  AT_LEAST_ONE_EMPLOYEE_EARN_5K = 'clmkyo5s43mf50bloj0jijo6q',
  LESS_THAN_100_EMPLOYEES_EARN_5K = 'clqfxhg3a9h4b0bn6gtu6335n',
  EMPLOYEES_RELATED_ENTITIES_IN_2022 = 'clmkynoq73luj0blotvx3ondv',
  RELATED_ENTITIES_PARENT_OR_SUBSIDIARIES = 'clmkynbebp2ra0alrq8f52uiq',
  NO_DISQUALIFYING_ACTIVITIES = 'clmkypzue3oj70blo3u9iqv44',
  OFFER_AUTOMATIC_ENROLLMENT_OPTIONS_IN_2022 = 'clmp3c9cc0xj70blkfv6zrkua',
  CONTRIBUTION_OF_3_PERCENT_OF_COMPENSATION = 'clmp3d07k0xwq0blk38t0q8z4',
  S_CORP_STARTUP_COST_CREDIT_2022 = 'clmqt2ohd0jnd0bn0xcxj2yb9',
  PARTNER_STARTUP_COST_CREDIT_2022 = 'clmtvuv766e6r0bn1qng4t36m',
  S_CORP_AUTO_ENROLL_CREDIT_2022 = 'clmp46ct31orr0blkhb1nojia',
  PARTNER_AUTO_ENROLL_CREDIT_2022 = 'clmp45yrp1oex0blk0jjgg83b',
  HOW_MUCH_SPENT_STARTUP_COST = 'clmqt1ty0zbwx0blk7w531tt8',
  HAS_EMPLOYER_CONTRIBUTIONS = 'clqfy11xi9u6g0bn6lzeg84ln',
  OFFER_AUTO_ENROLLMENT = 'clmqt22huzg3g0alghgujmfbk',
  AUTO_ENROLLMENT_FIRST_OFFERED = 'clqfxxqsbmudr0bmxvn1lw3b5',
  PLAN_PARTICIPANTS_WITH_OWNERSHIP = 'clmqrkym122vs0an27old3os6',
}

export const RetirementPlanCreditQuestionsToAnswers: Partial<
  Record<RetirementPlanCreditQuestions, any>
> = {
  [RetirementPlanCreditQuestions.RETIREMENT_PLAN_OFFERED_FOR_TAX_YEAR]: {
    YES: 'cltoou6l9dbtv07n1k2xx8w5w',
    NO: 'cltooue1w3h2i07k9wt3rc0in',
  },
  [RetirementPlanCreditQuestions.ESTABLISH_QUALIFIED_RETIREMENT_PLAN]: {
    YES: 'clmkykabg37530bn17xgd69br',
    NO: 'clmkyk3ac3hi20bloi7qc0mkh',
  },
  [RetirementPlanCreditQuestions.START_OFFERING_RETIREMENT_PLAN]: {
    _2024: 'clmkyl01k3ihz0bloyd8o2c58',
    _2023: 'clmkyl5n33ok60bn1lzjr5yex',
    _2022: 'clmkylajip3x10aloizy660pi',
    _2021: 'clmkyliwtp46x0aloe43nqj0m',
    _2020: 'clqfhrt9a7j860bmxmrmchjn6',
    _2019: 'clqfhsabb7itp0cn1b89biqcg',
    BEFORE_2020: 'clmkylp6gp4ur0alou9mic0d4',
    BEFORE_2019: 'clqfhsymf45730bmv3t7rs5sh',
    OFFERING_FOR_2024: 'clmkym12x38rr0bn1fo9wyaic',
    WE_DO_NOT_OFFER: 'cloekm32i2oci0bn0qj0628ff',
  },
  [RetirementPlanCreditQuestions.RELATED_ENTITIES_PARENT_OR_SUBSIDIARIES]: {
    YES: 'clmkyn2qhp5qu0alopjy58u3z',
    NO: 'clmkyn7uvp2qf0alrjtds1fyh',
  },
  [RetirementPlanCreditQuestions.NO_DISQUALIFYING_ACTIVITIES]: {
    YES: 'clmkypqlnp8ru0aloskttc4u4',
    NO: 'clmkypvs051sv0bn0nlz7q3zt',
  },
  [RetirementPlanCreditQuestions.OFFER_AUTOMATIC_ENROLLMENT_OPTIONS_IN_2022]: {
    YES: 'clmp3bypy2jvl0bn7fsz4q7jg',
    NO: 'clmp3c5022jz10bn7wxjqevpx',
  },
  [RetirementPlanCreditQuestions.CONTRIBUTION_OF_3_PERCENT_OF_COMPENSATION]: {
    YES: 'clmp3csdk2jx70bn3lxszdstu',
    NO: 'clmp3cxfp0z9t0alg2ggdbdy2',
  },
  [RetirementPlanCreditQuestions.AT_LEAST_ONE_EMPLOYEE_EARN_5K]: {
    YES: 'clmkyny9s3bhe0bn1pv3nmfo9',
    NO: 'clmkyo2vq3s7i0bn1idoao9tf',
  },
  [RetirementPlanCreditQuestions.HAS_EMPLOYER_CONTRIBUTIONS]: {
    YES: 'clqfy2a8g9vcc0bn62zdaq0p5',
    NO: 'clqfy22679v8b0bn6uprqn01a',
  },
  [RetirementPlanCreditQuestions.LESS_THAN_100_EMPLOYEES_EARN_5K]: {
    YES: 'clqfxm440mmxs0bmx4hg63is7',
    NO: 'clqfxmbtbmn180bmxpsgtp6um',
  },
  [RetirementPlanCreditQuestions.AUTO_ENROLLMENT_FIRST_OFFERED]: {
    _2023: 'clqfxylpvmuut0bmxcmtw8u1v',
    _2022: 'clqfxyup1muy50bmxd8jhjbwc',
    _2021: 'clqfxz3e9mv390bmxfp34kais',
    BEFORE_2021: 'clqfxze75mv9p0bmxsprmp3b4',
  },
  [RetirementPlanCreditQuestions.OFFER_AUTO_ENROLLMENT]: {
    YES: 'clmqt9ct44qvm0an2nirvcxn7',
    NO: 'clmqt9hrj6q4f0an6lmmpge3q',
  },
};

export enum DisableAccessCreditQuestions {
  DID_COMPANY_INCUR_EXPENSES = 'clox61zk11nb90an09w0vcgb2',
  DID_BUSINESS_MAKE_LESS_THAN_1MILLION_PREVIOUS_YEAR = 'clox63c9w1oux0an0mni4k9pl',
  SPENT_LAST_TAX_YEAR = 'clox63r5h1ojx0bn3jqgk55r6',
}

export const DisableAccessCreditQuestionsToAnswers: Partial<
  Record<DisableAccessCreditQuestions, any>
> = {
  [DisableAccessCreditQuestions.DID_COMPANY_INCUR_EXPENSES]: {
    YES: 'clox61n7z1mri0an0kp5rirzw',
    NO: 'clox61tjn1mzp0an0x4sg5evu',
  },
  [DisableAccessCreditQuestions.DID_BUSINESS_MAKE_LESS_THAN_1MILLION_PREVIOUS_YEAR]:
    {
      YES: 'clox62b1f1nqw0an0bv4ed54m',
      NO: 'clox62fug1npt0bn3blt5j5w6',
    },
};

export enum HealthcarePlanCreditQuestions {
  WHEN_DID_YOU_START_OFFERING_HEALTHCARE = 'clnxmlzyde1op0alrtv8oqytk',
  DID_YOU_PAY_PREMIUM_FOR_HEALTH_INSURANCE = 'clp0a5geuanjq0bn4omeckuu3',
  DID_YOU_PAY_50PERCENT_HEALTH_INSURANCE = 'cls3qnyafcfbw0blo04giu58p', // new health care question
  HAVE_YOU_CLAIMED_BEFORE_2022 = 'clp0a6nbmao7x0bn4ztpvmz2a',
  WHAT_PERCENTAGE_DO_EMPLOYER_PAY = 'clp0a6wwr0dp00bmunv2mhb1e',
  HOW_MANY_FTE_IN_TAX_YEAR = 'clp0aadh2apkt0bn4lgm2kw28',
  TOTAL_EMPLOYEES_WAGES_PAID_IN_TAX_YEAR = 'clp0abgh0apmw0bn09285jn5t',
  TOTAL_AMOUNT_PAID_FOR_HEALTH_INSURANCE = 'clp0abyv70flr0bmu5842jwz5',
  RECEIVED_STATE_PREMIUM_SUBSIDIES = 'clp0ajbi50h2j0blqq0zppwaq',
  AMOUNT_YOU_RECEIVED = 'clp0ajq1t0ioh0bmu66cpa8an',
  MAJORITY_EMPLOYEE_LOCATED = 'clp0aeiphaqq60bn0mwm8r7vy',
  PROOF_OF_SHOP_DOC = 'clrqwqjmxdm350bn4i9iuc2hq',
  MARKET_IDENTIFIER = 'clp0a66i80civ0blqst3dhmp1',
}

export const HealthcarePlanCreditQuestionsToAnswers: Partial<
  Record<HealthcarePlanCreditQuestions, any>
> = {
  [HealthcarePlanCreditQuestions.WHEN_DID_YOU_START_OFFERING_HEALTHCARE]: {
    _2023: 'clnxmkwqje0wu0alrip0nj9ig',
    _2022: 'clnxml1z1e1160alrorke5s9o',
    _2021: 'clnxml8ts447k0bn1yzpz5y4d',
    _2020: 'clnxmlgmje1b80alr5cje8tll',
    BEFORE_2020: 'clnxmlms443rj0bn4rragk9u1',
    WE_DO_NOT_OFFER: 'clnxmlw4le1mb0alr69o48wvq',
  },
  [HealthcarePlanCreditQuestions.DID_YOU_PAY_PREMIUM_FOR_HEALTH_INSURANCE]: {
    YES: 'clp1ay6p24gke0bln8trfaafq',
    NO: 'clp1aybqw4hkg0cn0zlw4x333',
    UNSURE: 'clrqrgm8389e70bn4lg0htgss',
  },
  [HealthcarePlanCreditQuestions.DID_YOU_PAY_50PERCENT_HEALTH_INSURANCE]: {
    YES: 'cls3qn2pda3y50bmxfs1x3dfx',
    NO: 'cls3qn8j3a42u0bmxo62ocih0',
  },
  [HealthcarePlanCreditQuestions.HAVE_YOU_CLAIMED_BEFORE_2022]: {
    YES: 'clp1b44tg7n440bn3mqft3vz6',
    NO: 'clp1b49le7lzb0bmu6d7x48qv',
  },
  [HealthcarePlanCreditQuestions.RECEIVED_STATE_PREMIUM_SUBSIDIES]: {
    YES: 'clp1bkrqv7r690bmujvghci4a',
    NO: 'clp1bkwhj4ony0cn0hon3sxbu',
  },
};
