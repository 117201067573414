import { LDFlagValue } from 'launchdarkly-js-client-sdk';

export enum FeatureFlagNameEnum {
  NEW_EXPENSE_CLASSIFICATION_FLOW = 'new-expense-classification-flow',
  EXPENSE_CLASSIFICATION_RD_VENDOR_EXPENSES = 'expense-classification--r-d-vendor-expenses',
  EXPENSE_CLASSIFICATION_MULTIPLE_RD_PROJECTS_TABLE = 'expense-classification--multiple-rd-projects-table',
  GOV_CONNECT_PAYROLL_GAP = 'gov-connect-payroll-gap',
  USE_2022_TAX_YEAR = '2022-federal-r-d-program',
  // GOV-2807 to remove once ramp-up complete
  SHOW_8974 = '8974--enable-client',
  MULTI_YEAR_SUPPORT = 'taxcredits--8974-multi-year-support',
  GOV_2021_8974_EXPERIENCE = 'gov--2021-8974-experience',
  GOV_ONBOARDING_ADDITIONAL_CD_QUESTIONS = 'gov--onboarding-cd-additional-questions',
  TM_ACH_ENABLED = 'treasury-management--ach-transfers',
  PAYMENTS_NEW_UI_V2 = 'payments-new-ui-v2',
  COREX_SHOW_NEW_ACCOUNT_CREATION = 'corex--show-new-account-creation-flow',
  COREX_SHOW_NEW_MS_LOGO = 'corex--show-new-ms-logo',
  COREX_SHOW_TAX_CREDIT_CARD = 'corex--show-tax-credit-card',
  COREX_REFER_EARN = 'corex--refer-and-earn',
  TAX_CREDITS_PAGE_CREDITBALANCECARD = 'tax-credits--tax-credits-page-creditbalancecard',
  TAX_CREDITS_EDUCATION_POP_UP = 'tax-credits--education-pop-up',
  TAX_CREDITS_YEA_IMPORT_ALERT = 'tax-credits--show-YEA-import-status-alert',
  COREX_SHOW_CHARGEBEE_UI_PORTALS = 'corex--show-chargebee-ui-portals',
  COREX_SHOW_RESOURCES_MODULE = 'corex--show-dashboard-resources-module',
  COREX_SHOW_ORDER_FORM_DISCOUNT = 'corex--show-order-form-discount',
  COREX_SHOW_SAVINGS_MODULE = 'corex--show-savings-module',
  COREX_SHOW_EDUCATIONAL_ONBOARDING = 'corex--show-educational-onboarding-experience',
  COREX_SHOW_2022_BOGO_DEAL = 'corex--show-2022-bogo-deal',
  COREX_SHOW_2023_BOGO_DEAL = 'corex--show-2023-bogo-deal',
  COREX_FORCE_2022_BOGO_DEAL = 'corex--force-2022-bogo-deal',
  COREX_FORCE_2023_BOGO_DEAL = 'corex--force-2023-bogo-deal',
  COREX_SHOW_UNIFIED_QUALIFICATION = 'corex--show-unified-qualification',
  COREX_SHOW_UNIVERSAL_ONBOARDING = 'corex--show-universal-onboarding',
  COREX_SHOW_LEGACY_PROGRAM_MODULE = 'corex--show-legacy-program-module',
  COREX_HIDE_PREQUALIFICATION_QUESTIONS = 'corex--hide-prequalification-questions',
  COREX_SHOW_REVAMPED_SUPPLIES_AND_SERVICES = 'corex--show-revamped-supplies-and-services',
  COREX_HIDE_PAYROLL_STEP_IN_QUALIFICATION = 'corex--hide-payroll-step',
  COREX_SHOW_QUALIFICATION_DQ_EXPERIENCE = 'corex--show-qualification-dq-experience',
  COREX_SHOW_ORDER_FORM_ATTESTATION = 'corex--show-order-form-attestation',
  TAX_CREDITS_SHOW_KEY_DATES_MODULE = 'tax-credits--show-key-dates-module',
  TAX_CREDITS_SHOW_CREDIT_ESTIMATES_MODULE = 'tax-credits--show-credit-estimates-module',
  TAX_CREDITS_SAVE_YEA_SURVEY_ATTESTATION = 'tax-credits--save-yea-survey-attestation',
  COREX_SHOW_ACCOUNT_MODULE_CREDIT_HISTORY = 'corex--show-account-module-credit-history',
  COREX_FAKE_CHARGEBEE_INTEGRATION = 'corex--fake-charge-bee-integration',
  TAX_CREDITS_TIER_3_ENABLED = 'tax-credits--tier-3-enabled',
  COREX_SHOW_PRODUCTION_BANNER = 'corex--show-production-maintenance-banner',
  COREX_SHOW_ONBOARDING_PROGRAM_TAX_YEAR_PAGE = 'corex--show-onboarding-program-tax-year-page',
  COREX_SHOW_ORDER_FORM_AUTO_RENEWAL_NOTICE = 'corex--show-order-form-auto-renewal-notice',
  TAX_CREDITS_SHOW_MISSED_REDEMPTION_ALERT = 'tax-credits--show-missed-redemption-alert',
  TAX_CREDITS_CLIENT_REVIEW_SIDEBAR = 'tax-credits--client-review-sidebar-flow',
  USE_AI_CHATBOT = 'use-AI-Chatbot',
  SHOW_AI_CHATBOT_SETTINGS = 'show-AI-chatbot-settings',
  SHOW_DEMO_FEATURES = 'show-demo-features',
  SHOW_ERC_QUALIFICATION = 'show-erc-qualification',
  SHOW_RETIREMENT_PLAN_CREDIT = 'show-retirement-plan-credit',
  SHOW_TYPEFORM_CREDIT_TODO = 'show-retirement-plan-credit',
  SHOW_CODAT_DISCONNECT_BUTTON = 'show-codat-disconnect-button',
  CALCULATE_FEE_W_DISCOUNT = 'calculate-fee-w-discount',
  REDIRECT_QUICKBOOKS_CALENDLY = 'redirect-quickbooks-calendly',
  HIDE_RETIREMENT_ORDER_FORM = 'hide-retirement-plan-order-form',
  SHOW_NEW_UNIFIED_ACCOUNT_CREATION = 'show-new-unified-account-creation',
  SHOW_UNIFIED_SOCIAL_SSO = 'show-unified-social-sso',
  ENABLE_STATE_GA_TAX_CREDIT = 'tax-credits--enable-state-GA-tax-credit',
  ENABLE_STATE_AZ_TAX_CREDIT = 'tax-credits--enable-state-AZ-tax-credit',
  ENABLE_STATE_MA_TAX_CREDIT = 'tax-credits--enable-state-MA-tax-credit',
  SHOW_DISABILITY_CREDIT_IN_ESTIMATES = 'tax-credits--show-disability-credit-in-estimates',
  SHOW_ERC_CREDIT_IN_ESTIMATES = 'tax-credits--show-erc-credit-in-estimates',
  SHOW_RETIREMENT_CREDIT_IN_ESTIMATES = 'tax-credits--show-retirement-credit-in-estimates',
  SHOW_WORK_OPPORTUNITY_CREDIT_IN_ESTIMATES = 'tax-credits--show-work-opportunity-credit-in-estimates',
  SHOW_HEALTHCARE_PLAN_CREDIT_IN_ESTIMATES = 'tax-credits--show-healthcare-plan-credit-in-estimates',
  SHOW_MST_SUBSCRIPTION_ACCEPTANCE_PAGE = 'mst-subscription--acceptance-page',
  SHOW_DISABLED_ACCESS_CREDIT = 'tax-credits--show-disabled-access-credit',
  ENABLE_AUTH0_INVITES = 'enable-auth0-invites',
  SHOW_SMALL_BUSINESS_HEALTHCARE_CREDIT = 'tax-credits--show-small-biz-healthcare-credit',
  SHOW_UNIFIED_ASSESSMENT_EXPERIENCE = 'tax-credits--show-unified-assessment-experience',
  FILING_IGNORE_TIME_CHECK = 'taxcredits--8974-ignore-time-check',
  ENABLE_YEA_PREFILL = 'enable-yea-prefill',
  SHOW_CHARGEBEE_PAYMENT_METHOD = 'payments--chargebee-payment-method',
  SHOW_PARTNER_CODE_WELCOME_PAGE = 'mst-partner-code-welcome-page',
  DASHBOARD_SHOW_EXISTING_CUSTOMER_FREE_CREDIT_MODAL = 'dashboard--show-existing-customer-free-credit-modal',
  DASHBOARD_SHOW_TALK_TO_AN_EXPERT_BUTTON = 'dashboard--show-talk-to-an-expert-button',
  PARTNER_SHOW_RED_ANTLER_QB_LANDING_PAGE = 'partner--show-red-antler-qb-landing-page',
  PARTNER_SHOW_RED_ANTLER_ONPAY_LANDING_PAGE = 'partner--show-red-antler-onpay-landing-page',
  PARTNER_SHOW_HYGRAPH_PARTNER_LANDING_PAGE = 'partner--show-red-antler-partner-landing-page',
  PARTNER_SHOW_PARTNER_GET_STARTED_AS_EXPANDABLE = 'partner--show-get-started-as-expandable',
  SHOW_SURVEY_REVIEW_PAGE = 'show-survey-review-page',
  SHOW_NEW_WELCOME_PAGE = 'show-new-welcome-page',
  SHOW_SURVEY_SKIP_BUTTON = 'tax-credits--show-survey-skip-button',
  SHOW_SHARE_ASSESSMENT = 'show-share-assessment',
  SHOW_URGENCY_BANNER = 'show-urgency-banner',
  SHOW_TAX_DEADLINES = 'show-tax-deadlines',
  SHOW_SHARED_SAVINGS = 'show-shared-savings',
  SHOW_EMPLOYEE_BENEFITS_BANNER = 'show-employee-benefits-banner',
  SHOW_CALL_US = 'show-call-us',
  SHOW_UPDATED_CREDITS_REDEMPTION = 'show-updated-credits-redemption',
  SHOW_SAVINGS_AGREEMENT = 'show-savings-agreement',
  YEA_SHOW_ASSESSMENT_TALK_TO_EXPERT = 'yea--show-assessment-talk-to-expert',
}

export enum FeatureFlagValueEnum {
  ON = 'on',
  OFF = 'off',
  MISSING = 'missing',
}

export type FeatureFlagSet = { [key in FeatureFlagNameEnum]?: LDFlagValue };
