import React, { useCallback, useEffect, useState } from 'react';
import { HeaderBar, StepsContainer, SurveyAttestation } from '../components';
import { SaveSurveyAttestation } from '../components/expense-classification/SurveyAttestation';
import {
  Alert,
  Color,
  Content,
  Delay,
  Divider,
  Flex,
  Text,
  Grid,
} from 'component-library';
import {
  ProgramNameEnum,
  Programs,
  ProgramSubStageEnum,
  SurveyNameEnum,
  RdProjectCategoryEnum,
  Page,
  ExpenseClassificationProcessingSteps,
  SubGroupNameEnum,
  AttestationEventTypeEnum,
} from 'lib/constants';
import { useSurveyQuestions } from 'lib/useSurveyQuestions';
import { CmsRenderTreeSubgroup, ProgramData } from 'lib/interfaces';
import { Loading } from 'components/util/Loading';
import RdExpensesSurveyQuestion from '../components/rd-expenses/RdExpensesSurveyQuestion';
import {
  useLegacyClients,
  useFeatureFlags,
  useTaxCreditsStores,
  useCompany,
  useCommonStores,
} from 'stores/useStores';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { useExpenseClassificationAnswerPrefill } from 'lib/useExpenseClassificationAnswerPrefill';
import { renderTaxYearInQuestionText } from 'lib/useQuestionGroup';
import { getAlertBySubGroup } from '../ExpenseClassification';
import { datadogLogs } from '@datadog/browser-logs';

const logger = datadogLogs.createLogger('RDExpenses');

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '40px 0',
    maxWidth: '1200px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 40px',
    overflow: 'hidden',
    minHeight: '100vh',
    backgroundColor: Color.neutral.white,
  },
  mainContent: {
    maxWidth: '1200px',
    width: '100%',
    margin: '0 auto',
    padding: '0 0 80px',
    boxSizing: 'border-box',
    position: 'relative',
  },
  divider: {
    marginTop: 24,
    maxWidth: '100%',
  },
  alert: {
    backgroundColor: Color.blue._10,
    border: `0.8px solid ${Color.blue._60}`,
  },
}));

interface RDExpensesStepProps {
  programId: number;
  onError: () => void;
}

const RDExpensesStep = observer(
  ({ programId, onError }: RDExpensesStepProps) => {
    const classes = useStyles();
    const { client } = useLegacyClients();
    const { taxCreditsPage } = useTaxCreditsStores();
    const featureFlags = useFeatureFlags();
    const { company } = useCompany();
    const { auth, chatbot } = useCommonStores();

    const [program, setProgram] = useState<ProgramData | null>(null);
    const [isOnContinueLoading, setIsOnContinueLoading] =
      useState<boolean>(false);

    const [hasConfirmedAnswers, setHasConfirmedAnswers] =
      useState<boolean>(false);

    useEffect(() => {
      client.GetProgram(programId).then((res) => {
        if (res.errorMsg || !res.data) {
          logger.error(
            res.errorMsg ?? `Error fetching program with id: ${programId}`,
          );
          return;
        }

        const { program } = res.data;
        setProgram(program);
      });
    }, [programId, client]);

    const surveyName: SurveyNameEnum =
      SurveyNameEnum.EXPENSE_QUALIFICATION_VENDOR_EXPENSES;

    const {
      isLoading,
      questionsToRender,
      surveyAnswers,
      addSurveyAnswers,
      getQuestionData,
      saveAnswers,
      isSurveyComplete,
      getSubGroupRenderData,
      addSurveyAnswersWithoutRerender,
      isQuestionRenderTreeSubGroupsPresent,
    } = useSurveyQuestions(surveyName, program?.taxYear || 0);

    // Prefill answers from other EC/Qual questions
    useExpenseClassificationAnswerPrefill(
      surveyName,
      program,
      addSurveyAnswersWithoutRerender,
    );

    interface SubGroupTitle {
      title?: string;
      subtitle?: string;
    }

    const updateProgramSubStage = useCallback(
      (programSubStage: ProgramSubStageEnum): Promise<string | undefined> => {
        return client
          .UpdateProgramSubStage({
            programId,
            programSubStage: programSubStage,
          })
          .then(({ errorMsg }) => {
            if (errorMsg) {
              console.error('Error: Could not update program substage.');
              return Promise.reject(errorMsg);
            }
          });
      },
      [client, programId],
    );

    // handle logic on continue here
    const onContinue = async () => {
      setIsOnContinueLoading(true);
      try {
        await saveAnswers();

        if (featureFlags.saveYeaSurveyAttestation) {
          await SaveSurveyAttestation({
            hasConfirmedAnswers,
            eventType:
              AttestationEventTypeEnum.YEAR_END_ASSESSMENT_VENDOR_EXPENSES_COMPLETE,
            userEmail: auth.user?.email || company?.adminEmail,
            taxYear: program!.taxYear,
            companyId: company.id,
            client,
          });
        }

        if (!taxCreditsPage.isYEAEditMode) {
          await updateProgramSubStage(
            ProgramSubStageEnum.EXPENSE_CLASSIFICATION_RD_EMPLOYEES,
          );
        }

        /**
         * Temporary solution for adding a single R&D project when the customer answers that they only
         * have one R&D project. When they answer that they have multiple projects, the API to create
         * a RDProject is handled in RdMultipleProjectsTable.tsx
         */
        const ONE_OR_MULTIPLE_PROJECTS_QUESTION_ID =
          'ckxgbenbc349s0e286inhai0w';
        const ONE_PROJECT_ANSWER_ID = 'ckxgbdvjc35v40g29xayydjx0';
        const MAIN_PROJECT_NAME_QUESTION_ID = 'ckxgcu3xs3sn60b76hzgr66q1';
        const oneOrMultipleProjectsAnswer =
          surveyAnswers[ONE_OR_MULTIPLE_PROJECTS_QUESTION_ID];
        const mainProjectNameAnswer =
          surveyAnswers[MAIN_PROJECT_NAME_QUESTION_ID] || '';
        const rdProjectToAdd = {
          name: mainProjectNameAnswer,
          category: RdProjectCategoryEnum.NOT_ANSWERED,
          passed4PartTest: false,
        };
        if (oneOrMultipleProjectsAnswer === ONE_PROJECT_ANSWER_ID) {
          await client.CreateRDProject(programId, rdProjectToAdd);
        }
        window.location.href = `/${Page.taxCredits}`;
      } catch (err) {
        logger.error(err);
        onError();
      }
      setIsOnContinueLoading(false);
    };

    const titleByOperator: Record<string, (...args: any[]) => SubGroupTitle> = {
      hasProgram: ({ programName }: { programName: ProgramNameEnum }) => {
        return {
          title: `Specific details for your ${Programs[programName].display} program.`,
        };
      },

      equals: ({
        questionId,
        answerValue,
      }: {
        questionId: string;
        answerValue: string;
      }) => {
        const question = getQuestionData(questionId as string);
        return {
          title: `Because you answered "${
            question!.answerIDs.find((a) => a.id === answerValue)?.text
          }" to "${question!.text}"`,
        };
      },

      and: (conditions) => {
        // HACK: Prioritizes the first condition when generating the header.
        const first = conditions[0];
        const [operator, condition] = Object.entries(first)[0];
        return titleByOperator[operator](condition);
      },

      or: (conditions) => {
        const first = conditions[0];
        const [operator, condition] = Object.entries(first)[0];
        return titleByOperator[operator](condition);
      },
    };

    const replaceTaxYear = (questionId: string) => {
      const question = getQuestionData(questionId as string);
      if (question) {
        const taxYear = program ? program.taxYear : new Date().getUTCFullYear(); // Every program should have a tax year
        question.text = renderTaxYearInQuestionText(question.text, taxYear);
      }
      return question!;
    };

    const titleBySubGroupId = (id: string): SubGroupTitle => {
      // HACK - Allow manual setting of title for edge-case subgroups.
      switch (id) {
        case 'vendorCloudExpenses':
          return {
            title: 'Cloud Computing',
            subtitle:
              'Certain uses of cloud computing services count as qualified research expense (QREs). We will calculate how much of these expenses you can qualify for R&D credits in the following questions.',
          };
        case 'vendorSupplyExpenses':
          return {
            title: 'Supplies (tangible items)',
            subtitle:
              'Some supplies may qualify towards the R&D credit, and we will calculate these qualifying expenses in the following section.',
          };
        case 'vendorProjects':
          return {
            title: 'R&D Business Components',
            subtitle:
              'This information is primarily for MainStreet’s review, and won’t be included in the forms you’ll submit to the IRS.',
          };
        default:
          return {};
      }
    };

    const updatedTitleBySubGroupId = (id: string): SubGroupTitle => {
      // HACK - Allow manual setting of title for edge-case subgroups.
      switch (id) {
        case 'vendorCloudExpenses':
          return {
            title: 'Cloud Computing',
            subtitle:
              'On average, our customers claim an additional $5,000 in R&D credit by including their cloud computing (rented server) expenses. We will use your data from this section to make sure you get every credit dollar you deserve.',
          };
        case 'vendorSupplyExpenses':
          return {
            title: 'Supplies (tangible items)',
            subtitle:
              'Most companies that develop physical goods use supplies that qualify towards the R&D credit. If your company has these expenses, including them here could increase your final credit amount by over 15%.',
          };
        case 'vendorProjects':
          return {
            title: 'R&D Business Components',
            subtitle:
              'Both MainStreet and your tax preparer may use this information to perform our due diligence, but it won’t be included in the forms you’ll submit to the IRS.',
          };
        default:
          return {};
      }
    };

    const getTitleBySubGroup = (
      subGroupName: string,
      subGroup: CmsRenderTreeSubgroup | undefined,
    ): SubGroupTitle => {
      if (subGroup) {
        if (typeof subGroup.showCondition === 'boolean') {
          return featureFlags.showRevampedSuppliesAndServices
            ? updatedTitleBySubGroupId(subGroupName)
            : titleBySubGroupId(subGroupName);
        }

        const [operator, conditions] = Object.entries(
          subGroup.showCondition,
        )[0];
        return titleByOperator[operator](conditions);
      }
      return {};
    };

    useEffect(() => {
      if (!program) {
        return;
      }
      client.GetRDProjects(program.id).then((res) => {
        program.rdProjects = res.data?.rdProjects || [];
      });
    }, [client, programId, program]);

    return (
      <div className={classes.container} data-testid='new-rd-expenses'>
        <HeaderBar currentPage={ExpenseClassificationProcessingSteps[0]} />
        <div className={classes.mainContent}>
          <div className={classes.root}>
            <StepsContainer
              showContinueButton={isSurveyComplete}
              onContinue={onContinue}
              onContinueLabel='Continue'
              stepTitle='R&D Expenses | Supplies and Services'
              continueDisabled={
                featureFlags.saveYeaSurveyAttestation && !hasConfirmedAnswers
              }
              isLoading={isOnContinueLoading}
            >
              <>
                {featureFlags.showRevampedSuppliesAndServices ? (
                  <Alert
                    type={'none'}
                    className={classes.alert}
                    text={
                      <Flex direction='column' gap={12}>
                        <Text
                          text={
                            'Wherever possible, we’ve pre-filled this info using your accounting data and/or previous work with MainStreet.'
                          }
                          variant='medium'
                        />
                        <Text
                          text={
                            'Please confirm that this data is complete and accurate. It may be helpful to ask your CTO!'
                          }
                          size={13}
                          color={Color.neutral._80}
                        />
                      </Flex>
                    }
                    iconColor={Color.blue._60}
                    backgroundColor={Color.blue._10}
                  />
                ) : (
                  <></>
                )}
                <Loading loading={isLoading}>
                  {Object.keys(questionsToRender).map((subGroupName) => {
                    const subGroup = getSubGroupRenderData(subGroupName);
                    const { title, subtitle } = getTitleBySubGroup(
                      subGroupName,
                      subGroup,
                    );
                    const alert = getAlertBySubGroup(
                      subGroupName as SubGroupNameEnum,
                    );

                    return program &&
                      questionsToRender[subGroupName].length > 0 ? (
                      featureFlags.showRevampedSuppliesAndServices ? (
                        <>
                          <Flex>
                            <Divider className={classes.divider} />
                          </Flex>
                          {questionsToRender[subGroupName].map((question) => {
                            question = replaceTaxYear(question.id);

                            return (
                              <Delay waitBeforeShow={350} key={question.id}>
                                <RdExpensesSurveyQuestion
                                  program={program}
                                  question={question}
                                  subGroupName={subGroupName}
                                  addSurveyAnswers={addSurveyAnswers}
                                  saveAnswers={saveAnswers}
                                  surveyAnswers={surveyAnswers}
                                  chatbot={chatbot}
                                />
                              </Delay>
                            );
                          })}
                        </>
                      ) : (
                        <React.Fragment key={`subgroup-${subGroupName}`}>
                          <Content paddingTopBottom={16}>
                            <Text text={title} size={18} variant='medium' />
                            {subtitle && <Text text={subtitle} />}
                          </Content>
                          {alert}
                          <Content
                            flex
                            flexDirection='column'
                            gap={16}
                            paddingLeftRight={0}
                            paddingTop={alert ? 32 : 0}
                          >
                            {questionsToRender[subGroupName].map((question) => {
                              question = replaceTaxYear(question.id);
                              return (
                                <Delay
                                  waitBeforeShow={
                                    surveyAnswers[question.id] ? 450 : 900
                                  }
                                  key={question.id}
                                >
                                  <RdExpensesSurveyQuestion
                                    program={program}
                                    question={question}
                                    subGroupName={subGroupName}
                                    addSurveyAnswers={addSurveyAnswers}
                                    saveAnswers={saveAnswers}
                                    surveyAnswers={surveyAnswers}
                                    chatbot={chatbot}
                                  />
                                </Delay>
                              );
                            })}
                          </Content>
                        </React.Fragment>
                      )
                    ) : null;
                  })}
                  {!isQuestionRenderTreeSubGroupsPresent && (
                    <>
                      <Content paddingTopBottom={16}>
                        <Text
                          textAlign={'center'}
                          text={
                            'We are currently experiencing issues, try refreshing or contact us at support@mainstreet.com'
                          }
                          variant='medium'
                        />
                      </Content>
                    </>
                  )}
                  {isQuestionRenderTreeSubGroupsPresent &&
                    featureFlags.saveYeaSurveyAttestation &&
                    isSurveyComplete && (
                      <Grid columns={3} padding={[24, 0]}>
                        <Grid.Cell columns={1} padding={[0, 24, 0, 0]} />
                        <Grid.Cell columns={2}>
                          <SurveyAttestation
                            checked={hasConfirmedAnswers}
                            onAttestation={() =>
                              setHasConfirmedAnswers(!hasConfirmedAnswers)
                            }
                          />
                        </Grid.Cell>
                      </Grid>
                    )}
                </Loading>
              </>
            </StepsContainer>
          </div>
        </div>
      </div>
    );
  },
);

export default RDExpensesStep;
