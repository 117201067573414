import {
  Card,
  CardFooter,
  Content,
  SideDrawer,
  SubQuestion,
  SurveyQuestion,
} from 'component-library';
import {
  AnswerType,
  AnswerValueType,
} from 'component-library/dashboard/surveyquestion/SurveyQuestion';
import {
  hasSubQuestions,
  isQuestionAnswered,
  SaveAnswersResponse,
} from 'lib/useSurveyQuestions';
import React, { useEffect, useState } from 'react';
import { CmsQuestionData } from 'lib/interfaces';

interface SurveyQuestionWithSubQuestionsProps {
  questionProps: CmsQuestionData;
  onComplete: () => void;
  helpDrawerContent?: React.ReactNode | JSX.Element;
  helpLinkText?: string;
  onHelpLinkClick?: (() => void) | string;
  customAnswerSubtitle?: string;
  saveAnswers?: () => Promise<SaveAnswersResponse>;
}

const SurveyQuestionWithSubQuestions = ({
  questionProps,
  onComplete,
  helpDrawerContent,
  helpLinkText,
  onHelpLinkClick,
  customAnswerSubtitle,
  saveAnswers,
}: SurveyQuestionWithSubQuestionsProps) => {
  const [isAnswered, setIsAnswered] = useState<boolean>(
    isQuestionAnswered(questionProps),
  );

  const [showHelpDrawer, setShowHelpDrawer] = useState<boolean>(false);

  useEffect(() => {
    setIsAnswered(isQuestionAnswered(questionProps));
  }, [questionProps]);

  const renderQuestion = (question: CmsQuestionData) => {
    const onChangeHandler = (value: AnswerValueType) => {
      question.answerValue = value;
      setIsAnswered(isQuestionAnswered(questionProps));
    };

    return (
      <Content paddingLeftRight={0} paddingTopBottom={0} key={question.id}>
        <SurveyQuestion
          dataTestId={question.id}
          answerType={question.answerType as AnswerType}
          onChange={onChangeHandler}
          placeholder={question.placeholder}
          answerValue={question.answerValue}
          withCard={false}
          label={question.text}
          onBlur={() => saveAnswers && saveAnswers()}
        />
      </Content>
    );
  };

  return (
    <Card noMargin>
      <SubQuestion
        dataTestId={questionProps.id}
        key={questionProps.id}
        title={questionProps.text}
        subtitle={questionProps.subtitle}
        helpLinkText={helpLinkText}
        onHelpLinkClick={
          helpDrawerContent ? () => setShowHelpDrawer(true) : onHelpLinkClick
        }
        showAnswerAsSubtitle={customAnswerSubtitle}
        fontSize={15}
        paddingTopBottom={16}
      />
      <Content paddingLeftRight={24} paddingTop={16} paddingBottom={32}>
        {hasSubQuestions(questionProps) &&
          questionProps.subQuestions &&
          questionProps.subQuestions.map((question) =>
            renderQuestion(question),
          )}
      </Content>
      <CardFooter
        secondaryCtaLabel={'Continue'}
        secondaryCtaDisabled={!isAnswered}
        secondaryOnClick={() => onComplete()}
      />
      {helpDrawerContent && (
        <SideDrawer
          show={showHelpDrawer}
          title={'Where to find this information'}
          closeToggle={() => setShowHelpDrawer(false)}
          drawerActions={<></>}
          drawerContent={helpDrawerContent}
        />
      )}
    </Card>
  );
};

export default SurveyQuestionWithSubQuestions;
