import React from 'react';
import { Alert, Color, Flex, Text } from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';

const useStyles = makeStyles(() => ({
  alert: {
    backgroundColor: Color.blue._10,
    border: `0.8px solid ${Color.blue._60}`,
  },
}));

export const PrefillAlert = observer(() => {
  const classes = useStyles();

  return (
    <Alert
      type={'none'}
      className={classes.alert}
      text={
        <Flex direction='column' gap={12}>
          <Text
            text={
              'Wherever possible, we’ve pre-filled this info using your payroll/accounting data or previous work with MainStreet.'
            }
            variant='medium'
          />
          <Text
            text={
              'Check the pre-filled information for accuracy and feel free to edit any details to keep your records up-to-date.'
            }
            size={13}
            color={Color.neutral._80}
          />
        </Flex>
      }
      iconColor={Color.blue._60}
      backgroundColor={Color.blue._10}
    />
  );
});
